import {ROUTES} from "@constants"

export default [
  {
    path: 'announcements',
    name: ROUTES.ADMIN.ANNOUNCEMENTS_LIST,
    meta: {
      title: 'お知らせ一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Announcement List',
        },
      ],
      activeKey: 'admin.master-booking.list'
    },
    component: () => import("@pages/admin/announcements/AnnouncementListPage"),
  },
  {
    path: 'announcements_mng',
    name: ROUTES.ADMIN.ANNOUNCEMENTS_MNG_LIST,
    meta: {
      title: 'お知らせ送信管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.AnnouncementMng List',
        },
      ],
      activeKey: 'admin.master-booking.list'
    },
    component: () => import("@pages/admin/announcements/AnnouncementMngListPage"),
  },
];
