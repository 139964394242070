<template>
  <validation-provider
    :name="name"
    :rules="rules"
    v-slot="{ errors, invalid, validated }"
  >
    <div class="app-input-group-date">
      <input
        type="text"
        ref="daterangepicker"
        :value="value"
        :disabled="disabled"
        @keydown="preventInput"
      />
      <span
        @click="trigger"
        ref="iconDatepicker"
        class="text-dark bg-white"
      >
        <i class="fa fa-calendar"></i>
      </span>
    </div>
    <label v-if="invalid && validated" class="error">
      {{ errors[0] }}
    </label>
  </validation-provider>
</template>

<script>
import moment from "moment";

export default {
  name: "AppDateRangePicker",

  props: [
    "options",
    "name",
    "colLabel",
    "colInput",
    "label",
    "rules",
    "onChange",
    "value",
    "boundaryClass",
    "inputStyle",
    "labelClass",
    "disabled",
    "format",
    "timePicker24Hour",
    "minDate",
    "timePickerSeconds",
    "timePickerMinutes",
    "yearAndMonthOnly",
    "hideNavigation"
  ],

  mounted: function () {
    this.register(this.value);
  },

  watch: {
    /*on change value*/
    value: function (value, oldValue) {
      let format = "YYYY-MM-DD"
      if (this.format === "YYYY-MM-DD H:mm") {
        format = "YYYY-MM-DD HH:mm"
      }
      if (this.format === "YYYY-MM-DD H:mm:ss") {
        format = "YYYY-MM-DD HH:mm:ss"
      }
      let vm = this;
      var formatDateValue = this.value
        ? this.__formatDate(this.value, format)
        : this.value;
      if (this.options && this.options.format === "yyyy/mm") {
        formatDateValue = this.value;
      }
      $(this.$refs.daterangepicker).val(formatDateValue).trigger("change");
      if (value !== oldValue) {
        this.onChange ? this.onChange(value) : "";
      }
      vm.$emit("change", formatDateValue);
      this.register(this.value);
    },

    /*on change options*/
    options: function (options) {},
  },

  methods: {
    handleIconDatepickerClick() {
      let vm = this;
      $(this.$refs.iconDatepicker).daterangepicker({
        "singleDatePicker": true,
        "showDropdowns": true,
        "timePicker": true,
        "timePicker24Hour": true,
        "autoApply": true,
        "timePickerMinutes": true,
        "maxSpan": {
          "days": 7
        },
        locale: {
          format: "YYYY-MM-DD hh:mm",
        },
      }, function(start, end, label) {
        // console.log(start, end, label);
      })
        .on("change", function (ev, args) {
          vm.$emit("input", $(this).val());
        })
        .on("hide", function (e) {
          e.stopPropagation();
        })
      ;
    },
    preventInput(e) {
      e.preventDefault();
      if (e.keyCode == 8 || e.keyCode == 46) {
        e.target.value = ''
        this.$emit("input", null);
      }
    },
    trigger () {
      this.$refs.daterangepicker.focus();
    },
    register(value) {
      let format = "YYYY-MM-DD hh:mm"
      if (this.format) {
        format = this.format
      }
      let timePicker24Hour = true
      if (this.timePicker24Hour) {
        timePicker24Hour = this.timePicker24Hour
      }
      let timePickerSeconds = false
      if (this.timePickerSeconds) {
        timePickerSeconds = this.timePickerSeconds
      }
      let timePickerMinutes = false
      if (this.timePickerMinutes) {
        timePickerMinutes = this.timePickerMinutes
      }
      let vm = this;
      $(this.$refs.daterangepicker).val(value);
      $(this.$refs.daterangepicker).daterangepicker({
        "singleDatePicker": true,
        "showDropdowns": true,
        "timePicker": timePicker24Hour,
        "timePicker24Hour": timePicker24Hour,
        "timePickerSeconds" : timePickerSeconds,
        "timePickerMinutes": timePickerMinutes,
        "autoUpdateInput": false,
        "autoApply": true,
        "maxSpan": {
          "days": 7
        },
        "minDate":  this.minDate,
        locale: {
          format: format,
          "daysOfWeek": [
            "日", "月", "火", "水", "木", "金", "土"
          ],
          "monthNames": [
            "1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"
          ],
          "applyLabel": "OK",
          "cancelLabel": "キャンセル",
        },
        ...this.options,
      }, function(start, end, label) {
        // console.log(start, end, label);
      })
        .on("change", function (ev, args) {
          if (vm.yearAndMonthOnly) {
            let date = $(this).val()
            if (date) {
              let value = moment(`${date}`, "YYYY-MM").format("YYYY-MM")
              vm.$emit("input", value);
            }
          } else {
            vm.$emit("input", $(this).val());
          }

        })
        .on("hide", function (e) {
          e.stopPropagation();
        })
        .on("change.daterangepicker",'select.yearselect', function () {
        })
        .on("showCalendar.daterangepicker", function (e, picker){
          // Hide the Navigation
          if (vm.hideNavigation) {
            $('.calendar-table th.prev').hide();
            $('.calendar-table th.next').hide();
            $('.calendar-table th select').addClass("select-width");
          }

          $('td.available').on("mousedown", function(e) {
            e.preventDefault();
          });
          e.stopPropagation();
          if (vm.yearAndMonthOnly) {
            $(".table-condensed thead tr:nth-child(2)").addClass("month-year-only")
            $(".table-condensed tbody").addClass("month-year-only")
            $(".yearselect").on('change', function (e) {
              let year = e.target.value;
              let month = parseInt($(".monthselect").val()) + 1;
              let value = moment(`${year} ${month}`, "YYYY-MM").format("YYYY-MM")
              vm.$emit("input", value);
            })

            $(".monthselect").on('change', function (e) {
              let month = parseInt(e.target.value) + 1;
              let year = parseInt($(".yearselect").val()) ;
              let value = moment(`${year} ${month}`, "YYYY-MM").format("YYYY-MM")
              vm.$emit("input", value);
            })
          }

        });

      $(this.$refs.daterangepicker).on('apply.daterangepicker', function(ev, picker) {
        ev.preventDefault();
        $(this).val(picker.startDate.format(format));
        vm.$emit("input", $(this).val());
      });
    }
  },

  destroyed: function () {
    $(this.$refs.daterangepicker).daterangepicker("destroy");
  },
};
</script>

<style>
  .drp-calendar{
    width: 100% !important;
  }
  .daterangepicker select.monthselect{
    width: 50% !important;
  }
  .daterangepicker select.yearselect {
    width: 50% !important;
  }

  .month-year-only {
    display: none;
  }
</style>
