import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'master-booking',
    name: ROUTES.ADMIN.BOOK_MASTER_LIST,
    meta: {
      title: '予約マスタ管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Master Booking List',
        },
      ],
      activeKey: 'admin.master-booking.list'
    },
    component: () => import("@pages/admin/book_master/BookMasterListPage"),
  },
  {
    path: 'master-booking/create',
    name: ROUTES.ADMIN.BOOK_MASTER_CREATE,
    meta: {
      title: '予約マスタ管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Master Booking List',
          'href': '/admin/master-booking?filters.apartment_id.equal=all&filters.facility_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Master Booking Create',
        },
      ],
      activeKey: 'admin.master-booking.create'
    },
    component: () => import("@pages/admin/book_master/BookMasterCreateOrEdit"),
  },
  {
    path: 'master-booking/:id/edit',
    name: ROUTES.ADMIN.BOOK_MASTER_EDIT,
    meta: {
      title: '予約マスタ管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Master Booking List',
        'href': '/admin/master-booking?filters.apartment_id.equal=all&filters.facility_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Master Booking Update',
        },
      ],
      activeKey: 'admin.master-booking.edit'
    },
    component: () => import("@pages/admin/book_master/BookMasterCreateOrEdit"),
  },
];
