import { AUTH, ROUTES } from "@constants";
import { store } from '@store'
import { __redirect } from "@utils";

export default function ({ next }) {
  let role = store.state.auth.currentAuth.role
  if (role) {
    return __redirect(AUTH[role].routes.dashboard)
  }
  __redirect(ROUTES.AUTH_LOGIN)
}
