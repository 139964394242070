<template>
  <div class="d-flex justify-content-center full-height full-width align-items-center">
    <div class="error-container text-center">
<!--      <h1 class="error-number">404</h1>-->
      <h2 class="semi-bold">このURLは無効です。再度手続きをしてください。</h2>
<!--      <h2 class="semi-bold">認証URLの有効期限が切れました。</h2>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkExpiredPage"
}
</script>
