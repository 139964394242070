import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'student-orders',
    name: ROUTES.ADMIN.KYY_STUDENT_ORDER_LIST,
    meta: {
      title: 'StudentOrder',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterStudentOrderList',
        },
      ],
      activeKey: 'admin.student_order.list'
    },
    component: () => import("@pages/admin/kyy_student_order/StudentOrderListPage"),
  },
  // {
  //   path: 'restaurants/:id/',
  //   name: ROUTES.ADMIN.KYY_RESTAURANT_EDIT,
  //   meta: {
  //     title: 'Restaurant',
  //     breadcrumb: [
  //       {
  //         'label': 'breadcrumbs.MasterRestaurantList',
  //         'href': '/admin/restaurants',
  //       },
  //       {
  //         'label': 'breadcrumbs.MasterRestaurantUpdate',
  //       },
  //     ],
  //     activeKey: 'admin.restaurant.edit'
  //   },
  //   component: () => import("@pages/admin/kyy_restaurant/RestaurantCreateOrEdit"),
  // },
];
