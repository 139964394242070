export const STATUS = [
  {
    id: 1,
    name: "メール送信済"
  },
  {
    id: 0,
    name: "メール未送信"
  },

]

export const FILTER_STATUS = [
  {
    id: 'all',
    name: "全て"
  },

  {
    id: 1,
    name: "メール送信済"
  },
  {
    id: 0,
    name: "メール未送信"
  },
]

export const PREVIEW_ACTION = 1;
export const CREATE_ACTION = 2;
export const EDIT_ACTION = 3;

export const ANNOUNCEMENTS_MNG_CONSTANT = {
  "STATUS" : STATUS,
  "FILTER_STATUS" : FILTER_STATUS,
  "PREVIEW_ACTION" : PREVIEW_ACTION,
  "CREATE_ACTION" : CREATE_ACTION,
  "EDIT_ACTION" : EDIT_ACTION
}
