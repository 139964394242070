export const STATUS = [
  {
    id: 1,
    name: "予約済"
  },
  {
    id: 0,
    name: "予約ブロック"
  },
]

export const REPEAT_TYPE = [
  {
    id: 0,
    name: "繰り返さない"
  },
  {
    id: 1,
    name: "毎日"
  },
  {
    id: 2,
    name: "毎週この時間"
  },
  {
    id: 3,
    name: "毎週平日（月～金)"
  },
  {
    id: 4,
    name: "カスタム"
  },
]

export const START_DATE = [
  {
    id: "",
    name: "--選択--"
  },
  {
    id: "0800",
    name: "08:00"
  },
  {
    id: "0900",
    name: "09:00"
  },
  {
    id: "1000",
    name: "10:00"
  },
  {
    id: "1100",
    name: "11:00"
  },
  {
    id: "1200",
    name: "12:00"
  },
]

export const END_DATE = [
  {
    id: "",
    name: "--選択--"
  },
  {
    id: "0900",
    name: "09:00"
  },
  {
    id: "1000",
    name: "10:00"
  },
  {
    id: "1100",
    name: "11:00"
  },
  {
    id: "1200",
    name: "12:00"
  },
  {
    id: "1300",
    name: "13:00"
  },
]

export const FILTER_STATUS = [
  {
    id : 'all',
    name : "全て"
  },
  {
    id: 1,
    name: "予約済"
  },
  {
    id: 2,
    name: "キャンセル"
  },
  {
    id: 0,
    name: "予約ブロック"
  },
]

export const RESERVATION_CONSTANT = {
  "STATUS" : STATUS,
  "REPEAT_TYPE" : REPEAT_TYPE,
  "START_DATE" : START_DATE,
  "END_DATE" : END_DATE,
  "FILTER_STATUS" : FILTER_STATUS
}
