export const AMOUNTS = [
  {
    id: "",
    name: "なし"
  },
  {
    id: 1,
    name: "1/5"
  },
  {
    id: 2,
    name: "2/5"
  },
  {
    id: 3,
    name: "3/5"
  },
  {
    id: 4,
    name: "4/5"
  },
  {
    id: 5,
    name: "完食"
  },
]

export const TYPE_OF_THREE_MEALS = [
  {
    id: 1,
    name: "朝食"
  },
  {
    id: 2,
    name: "昼食"
  },
  {
    id: 3,
    name: "夕食"
  }
]

export const TYPE_OF_SNACK = [
  {
    id: 1,
    name: "間食"
  },
  {
    id: 2,
    name: "水分"
  }
]

export const FILTER_TYPE_THREE_MEALS = [
  {
    id: 1,
    name: "朝昼夕の三食"
  },
  {
    id: 2,
    name: "間食"
  },
  {
    id: 3,
    name: "水分"
  },
]

export const TYPES = {"meal" : 1, "snack" : 2}
