export const STATUS = [
  {
    id: 1,
    name: "公開中"
  },
  {
    id: 0,
    name: "非公開"
  },
]

export const TYPES = [
  {
    id: 2,
    name: "ルームサービス （コンテンツ作成）"
  },
  {
    id: 0,
    name: "お知らせとして、コンテンツを作成する"
  },
  {
    id: 1,
    name: "お知らせとして、指定されたURLの内容をインラインで表示する"
  },
]

export const FILTER_TYPES = [
  {
    id: 'all',
    name: "全て"
  },
  {
    id: 1,
    name: "お知らせ"
  },
  {
    id: 2,
    name: "ルームサービス"
  },
]

export const FILTER_STATUS = [
  {
    id: 'all',
    name: "全て"
  },
  {
    id: 1,
    name: "公開中"
  },
  {
    id: 0,
    name: "非公開"
  },
]


export const INFORMATION_CONSTANT = {
  "status" : STATUS,
  "types" : TYPES,
  "filter_type" : FILTER_TYPES,
  "filter_status" : FILTER_STATUS
}
