import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'menus-student',
    name: ROUTES.ADMIN.MENU_STUDENT_LIST,
    meta: {
      title: 'Menu Student',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterMenuStudentList',
        },
      ],
      activeKey: 'admin.menus-student.list'
    },
    component: () => import("@pages/admin/menus_student/MenuStudentListPage"),
  },
  {
    path: 'menus-student/create',
    name: ROUTES.ADMIN.MENU_STUDENT_CREATE,
    meta: {
      title: 'Menu Student',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterMenuStudentList',
          'href': '/admin/menus-student',
        },
        {
          'label': 'breadcrumbs.MasterMenuStudentCreate',
        },
      ],
      activeKey: 'admin.menus-student.create'
    },
    component: () => import("@pages/admin/menus_student/MenuStudentCreateOrEdit"),
  },
  {
    path: 'menus-student/:id/edit',
    name: ROUTES.ADMIN.MENU_STUDENT_EDIT,
    meta: {
      title: 'Menu Student',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterMenuStudentList',
          'href': '/admin/menus-student',
        },
        {
          'label': 'breadcrumbs.MasterMenuStudentUpdate',
        },
      ],
      activeKey: 'admin.menus-student.edit'
    },
    component: () => import("@pages/admin/menus_student/MenuStudentCreateOrEdit"),
  },
];
