import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'qa-answer',
    name: ROUTES.ADMIN.ANSWER_LIST,
    meta: {
      title: 'カルーセル一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Answer List',
        },
      ],
      activeKey: 'admin.qa-answer.list'
    },

    component: () => import("@pages/admin/qa_chatbots/AnswerList"),
  },

  {
    path: 'qa-answer/create',
    name: ROUTES.ADMIN.ANSWER_CREATE,
    meta: {
      title: 'カルーセル一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Answer List',
          'href': '/admin/qa-answer?filters.big_category_id.equal=all&filters.medium_category_id.equal=all&filters.category_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Answer Create',
        },
      ],
      activeKey: 'admin.qa-answer.create'
    },
    component: () => import("@pages/admin/qa_chatbots/AnswerCreateOrEdit"),
  },

  {
    path: 'qa-answer/:id/edit',
    name: ROUTES.ADMIN.ANSWER_EDIT,
    meta: {
      title: 'カルーセル一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Answer List',
          'href': '/admin/qa-answer?filters.big_category_id.equal=all&filters.medium_category_id.equal=all&filters.category_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Answer Edit',
        },
      ],
      activeKey: 'admin.qa-answer.edit'
    },
    component: () => import("@pages/admin/qa_chatbots/AnswerCreateOrEdit"),
  },
];
