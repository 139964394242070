import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'orders',
    name: ROUTES.ADMIN.ORDER_LIST,
    meta: {
      title: '注文一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Order List',
        },
      ],
      activeKey: 'admin.order.list'
    },
    component: () => import("@pages/admin/orders/OrderListPage"),
  },
  {
    path: 'orders/:id/edit',
    name: ROUTES.ADMIN.ROOMSERVICE_ORDER_EDIT,
    meta: {
      title: '注文一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Order List',
          'href': '/admin/orders?filters.status_delivered.equal=all',
        },
        {
          'label': 'breadcrumbs.Order Edit',
        },
      ],
      activeKey: 'admin.orders.edit'
    },
    component: () => import("@pages/admin/orders/OrderCreateOrEdit"),
  },
  {
    path: 'orders/create',
    name: ROUTES.ADMIN.ROOMSERVICE_ORDER_CREATE,
    meta: {
      title: '注文一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Order List',
          'href': '/admin/orders?filters.status_delivered.equal=all',
        },
        {
          'label': 'breadcrumbs.Order Create',
        },
      ],
      activeKey: 'admin.orders.create'
    },
    component: () => import("@pages/admin/orders/OrderCreateOrEdit"),
  },
];
