import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'customers',
    name: ROUTES.ADMIN.CUSTOMER_LIST,
    meta: {
      title: '会員管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer List',
        },
      ],
      activeKey: 'admin.customers.list'
    },
    component: () => import("@pages/admin/customers/CustomerListPage"),
  },
  {
    path: 'customers/create',
    name: ROUTES.ADMIN.CUSTOMER_CREATE,
    meta: {
      title: '会員管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer List',
          'href': '/admin/customers?filters.status_enabled.equal=all&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Customer Create',
        },
      ],
      activeKey: 'admin.customers.create'
    },
    component: () => import("@pages/admin/customers/CustomerCreateOrEdit"),
  },
  {
    path: 'customers/:id/edit',
    name: ROUTES.ADMIN.CUSTOMER_EDIT,
    meta: {
      title: '会員管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer List',
          'href': '/admin/customers?filters.status_enabled.equal=all&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Customer Update',
        },
      ],
      activeKey: 'admin.customers.edit'
    },
    component: () => import("@pages/admin/customers/CustomerCreateOrEdit"),
  },
];
