<template>
  <validation-provider
    :name="name"
    :rules="rules"
    v-slot="{ errors, invalid, validated }"
  >
    <input
      type="password"
      maxlength="20"
      class="full-width app-input"
      :name="name"
      :id="id"
      :class="[invalid && validated ? 'app-error' : '']"
      :value="value"
      :disabled="disabled"
      @blur="blur"
      @input="$emit('input', $event.target.value)"
    />
    <label v-if="invalid && validated" class="error" :for="id">
      {{ errors[0] }}
    </label>
  </validation-provider>
</template>

<script>
export default {
  name: "AppInput",

  props: [
    "id",
    "name",
    "label",
    "type",
    "rules",
    "value",
    "placeholder",
    "disabled",
    "blur"
  ],

  data() {
    return {
      maxLength: false,
    };
  },

  mounted() {
    if (this.type && this.type.includes("number-length:")) {
      const length = this.type.split(":")[1];
      this.vcleave = {
        blocks: [parseInt(length)],
        numericOnly: true,
      };
    }
  },
};
</script>
