import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'beacons',
    name: ROUTES.ADMIN.BEACONS_LIST,
    meta: {
      title: 'ビーコン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Beacons List',
        },
      ],
      activeKey: 'admin.master-booking.list'
    },
    component: () => import("@pages/admin/beacons/BeaconListPage"),
  },
  {
    path: 'beacons/:id/edit',
    name: ROUTES.ADMIN.BEACONS_EDIT,
    meta: {
      title: 'ビーコン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Beacons List',
          'href': '/admin/beacons?filters.status_enabled.equal=all&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Beacons Edit',
        },
      ],
      activeKey: 'admin.master-booking.create'
    },
    component: () => import("@pages/admin/beacons/BeaconCreateOrEdit"),
  },
  {
    path: 'beacons/create',
    name: ROUTES.ADMIN.BEACONS_CREATE,
    meta: {
      title: 'ビーコン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Beacons List',
          'href': '/admin/beacons?filters.status_enabled.equal=all&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Beacons Create',
        },
      ],
      activeKey: 'admin.master-booking.create'
    },
    component: () => import("@pages/admin/beacons/BeaconCreateOrEdit"),
  },
  {
    path: 'beacons-history/:id/edit',
    name: ROUTES.ADMIN.BEACONS_HISTORY,
    meta: {
      title: 'ビーコン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Beacons List',
          'href': '/admin/beacons?filters.status_enabled.equal=all&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Beacons History',
        },
      ],
      activeKey: 'admin.master-booking.create'
    },
    component: () => import("@pages/admin/beacons/BeaconHistoryListPage"),
  },

];
