import { __setLocalStorage, __removeLocalStorage } from '@utils'

export const setAuthRole = (state, opened) => {
  state.currentAuth = state.data[opened]
}

export const updateAuth = (state, opened) => {
  let role = state.currentAuth.role;
  //update user
  if (opened.user !== undefined) {
    state.data[role].user = opened.user
    __setLocalStorage(`${role}_auth.user`, JSON.stringify(opened.user));
  }
  //update token
  if (opened.token !== undefined) {
    state.data[role].token = opened.token
    __setLocalStorage(`${role}_auth.token`, opened.token);
  }
  //update expire time
  if (opened.expire_time !== undefined) {
    const now = moment();
    const expireTime = moment(now.valueOf() + Number(opened.expire_time)).valueOf()
    state.data[role].expire_time = expireTime
    __setLocalStorage(`${role}_auth.expire_time`, String(expireTime));
  }
  state.currentAuth = state.data[role]
}

export const removeAuth = (state) => {
  let role = state.currentAuth.role;
  state.data[role] = {
    role: role,
    user: null,
    token: null,
    expire_time: null,
  }
  __removeLocalStorage(`${role}_auth.user`);
  __removeLocalStorage(`${role}_auth.token`);
  __removeLocalStorage(`${role}_auth.expire_time`);
  state.currentAuth = state.data[role]
}
