export default {
  "common": {
    "password": "Password",
    "password confirm": "Retype password",
    "email": "Email",
    "mail address": "Email address",
    "language": "Language",
    "next": "Next",
    "pre": "Pre",
    "name": "Name",
    "role": "Role",
    "action": "Edit",
    "status": "Status",
    "active": "Active",
    "deactive": "Inactive",
    "total": "Total",
    "create": "Add",
    "reset": "Reset",
    "perpage": "Number of records/page",
    "cancel": "Cancel",
    "confirm": "Yes",
    "no": "No",
    "search": "Search",
    "phone": "Phone number",
    "creator": "Creator",
    "created at": "Created at",
    "updated at": "Updated at",
    "address": "Address",
    "fax": "FAX",
    "save": "Save",
    "send": "Send",
    "error": "An error has occurred. Please try again.",
    "new_password": "New password",
    "new_password_confirm": "New password (confirmation)",
    "current_password": "Current password",
    "current_email": "Current email address",
    "new_email": "New email address",
    "msg update ok": "Updated successful.",
    "msg create ok": "Created successful.",
    "msg delete ok": "Deleted successfully.",
    "delete confirm": "Are you sure you want to delete?",
    "delete confirm title": "Delete",
    "view": "Diew",
    "edit": "Edit",
    "delete": "Delete",
    "information of": "Detailed information of {entry}",
    "loading": "Loading...",
    "list empty": "No data",
    "start_date": "Start date",
    "end_date": "End date",
    "price": "Price",
    "upload submit": "Upload",
    "choose file": "Choose file",
    "file ext err": "The file type is incorrect. Please upload a csv type file.",
    "import success": "File upload succeeded.",
    "import err": "File upload failed.",
    "no_select": 'No result found.',
    "yes": "Yes",
    "no data found": "No data found!",
    "text search": "text search!"
  },
  "auth": {
    "login": "Login",
    "login page": "Login",
    "forgot password page": "Reset Password",
    "forgot password note": "Send a password reset URL by email.",
    "verify ok": "Verify account successful",
    "reset pass ok": "Reset password successful",
    "reset password page": "Reset Password",
    "change password": "Change Password",
    "profile": "Profile",
    "reset password": "Reset Password",
    "forgot_password_label": "Click here if you have forgotten your password",
    'password note 1': "※The number of characters in the password is 8 to 20 characters.",
    'password note 2': "※Please include at least one letter, uppercase letters, and lowercase letters."
  },
  "dialog": {
    "confirm title": "Confirmation Dialog",
    "confirm body": "Are you sure do this action?"
  },
  "nav": {
    "dashboard": "Dashboard",
    "logout": "Logout",
    "user list": "List Users",
    "admin account": "Admin accounts",
    "profile": "Profile",
    "users setting": "Users Setting",
    "Account Admin": "Account Admin",
    "Mansion List": "Mansion List",
    "Master Booking Management": "Master Booking Management",
    "Customer Booking List": "Customer Booking List",
    "Booking Schedule": "Booking Schedule",
    "Member List": "Member List",
    "Beacon Management": "Beacon Management",
    "Gateway Management": "Gateway Management",
    "Facility Management": "Facility Management",
    "Information": "Information",
    "Room Service": "Room Service",
    "Category Management": "Category Management",
    "Menu Management": "Menu Management",
    "Order List": "Order List",
    "Sales List": "Sales List",
    "Receipt Management": "Receipt Management",
    "QA Chatbot": "QA Chatbot",
    "Large Category List": "Large Category List",
    "Medium Category List": "Medium Category List",
    "Small Category List": "Small Category List",
    "Carousel List": "Carousel List",
    "Download CSV": "Download CSV",
    "Setting Notification Mail": "Setting Notification Mail",
    "Restaurant Management": "Restaurant Management",
    "Announcement Management": "Announcement Management",
    "AnnouncementMng Management": "AnnouncementMng Management",
    "Transport Management": "Transport Management",
    "Customer List" : "会員管理"
  },
  "breadcrumbs": {
    "Home": "Dashboard",
    "Dashboard": "Dashboard",
    "Users management": "Users",
    "Create user": "Create user",
    "Admin users": "Admin account setting",
    "Profile": "Profile",
    "Users": "Users setting"
  },
  "users": {
    "admin user list": "Admin Users List",
    "create user": "Create User",
    "update user": "Update User",
    "resent_active_email": 'Resent Active URL',
    "sent_email_msg": "Sent Email Successful.",
    "role": "Authority",
    "admin": "Admin",
    "user": "User",
  },
  "pages": {
    "404_msg": "Not Found Page."
  }
}
