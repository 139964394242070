export const STATUS_DISP_CROWED = [
  {
    id: 1,
    name: "公開中",
    color : "#0fb131"
  },
  {
    id: 0,
    name: "非公開",
    color : "#ED8077"
  },
]
export const STATUS_BIZ_TYPE = [

  {
    id: 0,
    name: "施設",
  },
  {
    id: 1,
    name: "組合",
  },
]

export const FILTER_STATUS_DISP_CROWED = [
  {
    id : 'all',
    name : "全て"
  },
  {
    id: 1,
    name: "公開中"
  },
  {
    id: 0,
    name: "非公開"
  }
]

export const STATUS_DISP_GUIDE = [
  {
    id: 1,
    name: "公開中"
  },
  {
    id: 0,
    name: "非公開"
  },
]

export const STATUS_DISP_SIGNAGE = [
  {
    id: 0,
    name: "無効",
    color : "#ED8077"
  },
  {
    id: 1,
    name: "有効",
    color : "#0fb131"
  }
]

export const STATUS_BOOKING_FLOW_TYPE = [
  {
    id: 1,
    name: "予約者情報入力画面"
  },
  {
    id: 2,
    name: "予約スケジュール画面"
  }
]

export const STATUS_GATEWAY_DISP_TYPE = [
  {
    id: 0,
    name: "混雑状況"
  },
  {
    id: 1,
    name: "空き状況"
  }
]

export const GATEWAY_TIME = [
  {
    id: "",
    name: "--選択--"
  },
  {
    id: 10,
    name: "10"
  },
  {
    id: 15,
    name: "15"
  },
  {
    id: 30,
    name: "30"
  },
  {
    id: 60,
    name: "60"
  },
]

export const DETAIL_STATUS_BUTTON = [
  {
    id: 1,
    name: "詳細"
  },
  {
    id: 2,
    name: "詳細・予約"
  },
  {
    id: 3,
    name: "詳細・経路案内"
  },
  {
    id: 4,
    name: "詳細・予約・経路案内"
  },
]

export const MANAGE_TYPE = [
  {
    id: 0,
    name: "手動→",
    color : "#0fb131"
  },
  {
    id: 2,
    name: "自動→",
    color : "#ED8077"
  },

]

export const AUTO_STATUS = [
  {
    id: 0,
    name: "営業時間外",
    color : "#797676"
  },
  {
    id: 1,
    name: "空いています",
    color : "#cef2ac"
  },
  {
    id: 2,
    name: "やや混んでいます",
    color : "#f7cd88"
  },
  {
    id: 3,
    name: "混んでいます",
    color : "#ED8077"
  },

]

export const AUTO_TIME_WORKING = [
  {
    id: 0,
    name: "時間外",
    color : "#797676"
  },
  {
    id: 1,
    name: "営業中",
    color : "#0fb131"
  },
]


export const FACILITIES_CONSTANT = {
  "biz_type" : STATUS_BIZ_TYPE,
  "disp_crowed" : STATUS_DISP_CROWED,
  "disp_guide" : STATUS_DISP_GUIDE,
  "disp_signage" : STATUS_DISP_SIGNAGE,
  "booking_flow_type" : STATUS_BOOKING_FLOW_TYPE,
  "gateway_disp_type" : STATUS_GATEWAY_DISP_TYPE,
  "gateway_unit_time" : GATEWAY_TIME,
  "detail_button_status" : DETAIL_STATUS_BUTTON,
  "manage_type"  : MANAGE_TYPE,
  "auto_status" : AUTO_STATUS,
  "time_working" : AUTO_TIME_WORKING,
  "filter_status_disp_crowded" : FILTER_STATUS_DISP_CROWED
}

