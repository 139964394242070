<template>
  <validation-provider :name="name" :rules="rules" v-slot="{ errors, invalid, validated }">
    <select class="form-control" ref='select' :disabled="disabled" :multiple="true"  style="width: 100%;">
      <template v-if="optionsData">
        <option :key="index" v-for="(item, index) in optionsData" :value="item.id">
          {{ item.name }}
        </option>
      </template>
      <slot></slot>
    </select>
    <label v-if="invalid && validated" class="error app-input-error-label-2">{{ errors[0] }}</label>
  </validation-provider>
</template>

<script>
export default {
  name: "AppSelectMulti",

  props: [
    'options',
    'value',
    'name',
    'label',
    'rules',
    'value',
    'optionsData',
    'inputStyle',
    'boundaryClass',
    'labelClass',
    'onChange',
    'disabled',
  ],

  mounted: function () {
    this.initSelect()
  },

  watch: {
    /*on change value*/
    value: function (value, oldValue) {
      let vm = this;
      $(this.$refs.select)
        .val(this.value)
        .trigger('change', {ignore: true});
      vm.$emit('change', this.value);
    },

    /*on change options*/
    options: function (options, oldOptions) {
      if (options && JSON.stringify(options) !== JSON.stringify(oldOptions)) {
        $(this.$refs.select).select2(options)
      }
    },

    optionsData: function (value, oldValue) {
      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        this.$nextTick(() => {
          $(this.$refs.select).off().select2('destroy');
          this.initSelect()
          $(this.$refs.select).val(this.value).trigger('change')
        })
      }
    }
  },

  methods: {
    initSelect() {
      let vm = this;
      var onChange = this.onChange;
      $(this.$refs.select).val(this.value);
      $(this.$refs.select)
        .select2({
          ...this.options,
          "closeOnSelect": false,
          "language": {
            "noResults": () => {
              return this.$t('common.no_select');
            }
          },
        })
        .on('change', function (ev, args) {
          if (!(args && "ignore" in args)) {
            if (onChange) {
              onChange($(this).val());
            }
            vm.$emit('input', $(this).val());
          }
        });
      if (this.options?.containerCssClass) {
        $('.' + this.options?.containerCssClass).closest('.select2-container').addClass(this.options?.containerCssClass);
      }
    }
  },

  destroyed: function () {
    $(this.$refs.select).off().select2('destroy');
  },
}
</script>
