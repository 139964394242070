import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'facilities/create',
    name: ROUTES.ADMIN.FACILITY_CREATE,
    meta: {
      title: '館内施設管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Facility List',
          'href': '/admin/facilities?filters.status_disp_crowded.equal=all&perPage=99999&sortBy=apartment_id&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Facility Create',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/facilities/FacilityCreateOrEditPage"),
  },
  {
    path: 'facilities/:id/edit',
    name: ROUTES.ADMIN.FACILITY_EDIT,
    meta: {
      title: '館内施設管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Facility List',
          'href': '/admin/facilities?filters.status_disp_crowded.equal=all&perPage=99999&sortBy=apartment_id&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Facility Edit',
        },
      ],
      activeKey: 'admin.customer-meals.create'
    },
    component: () => import("@pages/admin/facilities/FacilityCreateOrEditPage"),
  },
  {
    path: 'facilities/:id/manage-status',
    name: ROUTES.ADMIN.FACILITY_MANAGE_STATUS,
    meta: {
      title: '館内施設管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Facility List',
          'href': '/admin/facilities?filters.status_disp_crowded.equal=all&perPage=99999&sortBy=apartment_id&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Facility Manage Status',
        }
      ],
      activeKey: 'admin.customer-meals.create'
    },
    component: () => import("@pages/admin/facilities/FacilityManageStatusPage"),
  },
  {
    path: 'facilities',
    name: ROUTES.ADMIN.FACILITY_LIST,
    meta: {
      title: '館内施設管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Facility List'
        },
      ],
      activeKey: 'admin.customer-meals.create'
    },
    component: () => import("@pages/admin/facilities/FacilityList"),
  },
];
