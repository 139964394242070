import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'invoice-sending',
    name: ROUTES.ADMIN.KYY_STUDENT_INVOICE_LIST,
    meta: {
      title: 'SendingInvoice',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterStudentInvoiceList',
        },
      ],
      activeKey: 'admin.invoice_sending'
    },
    component: () => import("@pages/admin/kyy_invoice_sending/InvoiceSendingPage"),
  },
  {
    path: 'invoice-sending/create',
    name: ROUTES.ADMIN.KYY_STUDENT_INVOICE_CREATE,
    meta: {
      title: 'SendingInvoice',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterStudentInvoiceList',
          'href': '/admin/invoice-sending',
        },
        {
          'label': 'breadcrumbs.MasterStudentInvoiceCreate',
        },
      ],
      activeKey: 'admin.invoice_sending.create'
    },
    component: () => import("@pages/admin/kyy_invoice_sending/StudentInvoiceCreate"),
  },
];
