export const FILTER_STATUS_DELIVERED = [
  {
    id : 'all',
    name : "全て"
  },
  {
    id: 0,
    name: "注文済",
  },
  {
    id: 2,
    name: "キャンセル",
  },
  {
    id: 1,
    name: "受渡済",
  },
]

export const STATUS_DELIVERED = [
  {
    id: 0,
    name: "注文済",
  },
  {
    id: 2,
    name: "キャンセル",
  },
  {
    id: 1,
    name: "受渡済",
  },
]

export const ORDER_LIST_CONSTANT = {
  "FILTER_STATUS_DELIVERED" : FILTER_STATUS_DELIVERED,
  "STATUS_DELIVERED" : STATUS_DELIVERED
}

