import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'category',
    name: ROUTES.ADMIN.KYY_CATEGORY_LIST,
    meta: {
      title: 'Category',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCategoryList',
        },
      ],
      activeKey: 'admin.category.list'
    },
    component: () => import("@pages/admin/kyy_category/CategoryListPage"),
  },
  {
    path: 'category/create',
    name: ROUTES.ADMIN.KYY_CATEGORY_CREATE,
    meta: {
      title: 'Category',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCategoryList',
          'href': '/admin/category',
        },
        {
          'label': 'breadcrumbs.MasterCategoryCreate',
        },
      ],
      activeKey: 'admin.category.create'
    },
    component: () => import("@pages/admin/kyy_category/CategoryCreateOrEdit"),
  },
  {
    path: 'category/:id/edit',
    name: ROUTES.ADMIN.KYY_CATEGORY_EDIT,
    meta: {
      title: 'Category',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCategoryList',
          'href': '/admin/category',
        },
        {
          'label': 'breadcrumbs.MasterCategoryUpdate',
        },
      ],
      activeKey: 'admin.category.edit'
    },
    component: () => import("@pages/admin/kyy_category/CategoryCreateOrEdit"),
  },
];
