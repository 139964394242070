<template>
  <validation-provider
    :name="name"
    :rules="rules"
    v-slot="{ errors, invalid, validated }"
  >
    <textarea
      v-if="vcleave"
      :type="type"
      class="full-width"
      :class="['app-input', invalid && validated ? 'app-error' : '']"
      :autocomplete="autocomplete || 'off'"
      :value="value"
      @input="onInput"
      :disabled="disabled"
      :maxlength="maxLength"
      v-cleave="vcleave"
      :placeholder="placeholder"
    ></textarea>
    <textarea
      v-else
      :type="type"
      class="full-width"
      :class="['app-input', invalid && validated ? 'app-error' : '']"
      :autocomplete="autocomplete || 'off'"
      :value="value"
      @input="onInput"
      :disabled="disabled"
      :maxlength="maxLength"
      :placeholder="placeholder"
      rows="4"
    ></textarea>

    <label v-if="invalid && validated" class="error" :for="id">
      {{ errors[0] }}
    </label>
  </validation-provider>
</template>

<script>
export default {
  name: "AppTextarea",

  props: [
    "name",
    "label",
    "type",
    "rules",
    "value",
    "disabled",
    "autocomplete",
    "inputStyle",
    "boundaryClass",
    "labelClass",
    "colLabel",
    "colInput",
    "placeholder",
  ],

  data() {
    return {
      vcleave: false,
      maxLength: false,
    };
  },

  mounted() {
    if (this.type && this.type.includes("number-length:")) {
      const length = this.type.split(":")[1];
      this.vcleave = {
        blocks: [parseInt(length)],
        numericOnly: true,
      };
    }
    if (this.type && this.type.includes("string:")) {
      const length = this.type.split(":")[1];
      this.maxLength = length;
    }
    switch (this.type) {
      case "bigint": {
        this.vcleave = {
          blocks: [18],
          numericOnly: true,
        };
        break;
      }
      case "int": {
        this.vcleave = {
          blocks: [9],
          numericOnly: true,
        };
        break;
      }
      case "digits": {
        this.vcleave = {
          blocks: [191],
          numericOnly: true,
        };
        break;
      }
      case "email":
      case "string": {
        this.maxLength = 191;
        break;
      }
      case "string1024": {
        this.maxLength = 1024;
        break;
      }
    }
  },
  methods: {
    onInput (event) {
      this.$emit('input', event.target.value)
      if (event.target.getAttribute("maxlength") != null && event.target.value.length > event.target.getAttribute("maxlength")) {
        event.target.blur()
      }
    }
  }
};
</script>
