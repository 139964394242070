import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'reservation/create',
    name: ROUTES.ADMIN.RESERVATION_CREATE,
    meta: {
      title: '予約者一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Reservation List',
          'href': '/admin/reservation?filters.date.equal=2022-01-20&filters.status_booking_equal.equal=all',
        },
        {
          'label': 'breadcrumbs.Reservation Create',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/reservation/ReservationCreateOrEdit"),
  },
  {
    path: 'reservation/:id/edit',
    name: ROUTES.ADMIN.RESERVATION_EDIT,
    meta: {
      title: '予約者一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Reservation List',
          'href': '/admin/reservation?filters.date.equal=2022-01-20&filters.status_booking_equal.equal=all',
        },
        {
          'label': 'breadcrumbs.Reservation Edit',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/reservation/ReservationCreateOrEdit"),
  },
  {
    path: 'reservation',
    name: ROUTES.ADMIN.RESERVATION_LIST,
    meta: {
      title: '予約者一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Reservation List',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/reservation/ReservationListPage"),
  },
];
