export const STATUS_ORDER = [
  {
    id: 1,
    name: "Cần xác nhận",
  },
  {
    id: 2,
    name: "Đã xác nhận",
  },
  {
    id: 3,
    name: "Đã hủy",
  },
]

export const TABLE_ORDER_CONSTANT = {
  "STATUS_ORDER" : STATUS_ORDER,
}

