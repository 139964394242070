import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'information/create',
    name: ROUTES.ADMIN.INFORMATION_CREATE,
    meta: {
      title: 'インフォメーション',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Information List',
          'href': '/admin/information?filters.infor_type.equal=all&filters.status_publishing.equal=all&filters.from_date.between=2022-01-07&filters.to_date.between=2022-01-07',
        },
        {
          'label': 'breadcrumbs.Information Create',
        },
      ],
      activeKey: 'admin.information.create'
    },
    component: () => import("@pages/admin/infomations/InformationCreateOrEdit"),
  },
  {
    path: 'information/:id/edit',
    name: ROUTES.ADMIN.INFORMATION_EDIT,
    meta: {
      title: 'インフォメーション',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Information List',
          'href': '/admin/information?filters.infor_type.equal=all&filters.status_publishing.equal=all&filters.from_date.between=2022-01-07&filters.to_date.between=2022-01-07',
        },
        {
          'label': 'breadcrumbs.Information Edit',
        },
      ],
      activeKey: 'admin.information.edit'
    },
    component: () => import("@pages/admin/infomations/InformationCreateOrEdit"),
  },
  {
    path: 'information',
    name: ROUTES.ADMIN.INFORMATION_LIST,
    meta: {
      title: 'インフォメーション',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Information List',
        },
      ],
      activeKey: 'admin.information.list'
    },
    component: () => import("@pages/admin/infomations/InfomationListPage"),
  },
];
