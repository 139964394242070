import i18n from '@i18n'

export const STUDENT_ORDER_STATUS = [
  {
    id: 1,
    name: "Đã hoàn thành",
  },
  {
    id: 2,
    name: "Đã huỷ",
  },
]

export const STUDENT_PAYMENT_METHOD = [
  {
    id: 1,
    name: i18n.t('student_order.payment_options.transfer'),
  },
  {
    id: 2,
    name: i18n.t('student_order.payment_options.in_store'),
  },
  {
    id: 3,
    name: i18n.t('student_order.payment_options.at_school'),
  },
]

export const STUDENT_PAID_STATUS = [
  {
    id: 1,
    name: i18n.t('student_order.paid_status_options.unpaid'),
  },
  {
    id: 2,
    name: i18n.t('student_order.paid_status_options.paid'),
  },
]

export const ITEM_SIZE = [
  {
    id: 1,
    name: "S"
  },
  {
    id: 2,
    name: "M"
  },
  {
    id: 3,
    name: "L"
  }
]

export const ITEM_SIZE_VALUES = {
  'S': 1,
  'M': 2,
  'L': 3,
}

export const STUDENT_ORDER_LIST_CONSTANT = {
  "STUDENT_ORDER_STATUS" : STUDENT_ORDER_STATUS,
  "STUDENT_PAYMENT_METHOD" : STUDENT_PAYMENT_METHOD,
  "STUDENT_PAID_STATUS" : STUDENT_PAID_STATUS,
  "ITEM_SIZE": ITEM_SIZE,
  "ITEM_SIZE_VALUES": ITEM_SIZE_VALUES
}

