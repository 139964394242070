import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ja from './src/ja';
import en from './src/en';

Vue.use(VueI18n);
const messages = {
  ja: ja,
  en: en,
};
const i18n = new VueI18n({
  locale: 'ja', // set locale
  messages,
  fallbackLocale: 'en',
});

export default i18n;
