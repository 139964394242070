import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'apartments',
    name: ROUTES.ADMIN.APARTMENT_LIST,
    meta: {
      title: 'マンション一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Apartment List',
        },
      ],
      activeKey: 'admin.apartments.list'
    },
    component: () => import("@pages/admin/apartments/ApartmentListPage"),
  },
  {
    path: 'apartments/create',
    name: ROUTES.ADMIN.APARTMENT_CREATE,
    meta: {
      title: 'マンション一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Apartment List',
          'href': '/admin/apartments?filters.pref_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Apartment Create',
        },
      ],
      activeKey: 'admin.apartments.create'
    },
    component: () => import("@pages/admin/apartments/ApartmentCreateOrEdit"),
  },
  {
    path: 'apartments/:id/edit',
    name: ROUTES.ADMIN.APARTMENT_EDIT,
    meta: {
      title: 'マンション一覧',
      breadcrumb: [
        {
        'label': 'breadcrumbs.Apartment List',
        'href': '/admin/apartments?filters.pref_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Apartment Update',
        },
      ],
      activeKey: 'admin.apartments.edit'
    },
    component: () => import("@pages/admin/apartments/ApartmentCreateOrEdit"),
  },
];
