import {extend, validate} from "vee-validate";
import ja from "../ja";
import {min} from "vee-validate/dist/rules";
import {illegalInteger, negativeSmallInt, unsignedSmallInt} from "../../utils";

extend("after_date", {
  params: ['target'],
  validate(value, {target}) {
    if (value && target) {
      return moment(value).diff(moment(target)) > 0;
    }
    return true;
  },
  message(field, obj) {
    return ja.names[field] + 'には' + ja.names[obj.target] + 'より後の日付を指定してください。';
  }
});
extend("after_eq_date", {
  params: ['target'],
  validate(value, {target}) {
    if (value && target) {
      return moment(value).diff(moment(target)) >= 0;
    }
    return true;
  },
  message(field, obj) {
    return ja.names[field] + 'は' + ja.names[obj.target] + '以降の日付を指定してください。';
  }
});

extend('numeric', {
  validate(value, args) {
    return (/^[0-9０-９]+$/).test(value);
  }
});

extend('double42', {
  async validate(value, { min, max }) {
    const res = await validate(value, 'max_value:99.999')
    return res.valid;
  },
});

extend('double52', {
  async validate(value, { min, max }) {
    const res = await validate(value, 'max_value:999.999')
    return res.valid;
  },
});

extend('email', {
  validate(value) {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,40}$/.test(value)
  }
})
extend('password', {
  validate(value) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#\$%\^\&*\)\(\\\/\[\]{}|,;?"':<>~+=._-]{8,20}$/.test(value)
  }
})

extend('email_unique', {
  params: ['target'],
  validate(value, {target}) {
    return !target;
  },
})

extend('ja_postal_code', {
  params: ['target'],
  validate(value, {target}) {
    return value && value.length === 8 && !target;
  },
})

extend('phone_mobile', {
  validate(value) {
    return String(value).length === 11;
  },
})

extend('phone_home', {
  validate(value) {
    return String(value).length === 10;
  },
})

extend('fax', {
  validate(value) {
    return String(value).length === 10;
  },
})

extend('confirm_pass', {
  params: ['target'],
  validate(value, {target}) {
    return value === target;
  },
})

extend('url', {
  validate(value) {
    // return /^(?:http(s)?:\/\/)?([\w-]+\.[\w-]+)((\.[\w-]+){1,2})?$/gm.test(value)
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(value)
  },
  message: '有効なURLを入力してください。'
})

extend('katakana', {
  validate(value) {
    return /^([ァ-ンｧ-ﾝﾞﾟ_ ]|ー)+$/.test(value)
  },
  // message: 'フリガナに使用できない文字があります。'
})

extend('no_space', {
  validate(value) {
    return /^\S+$/.test(value)
  }
})

extend('phone_number', {
  async validate(value, { min, max }) {
    const res = await validate(value, `numeric|min:10|max:12`)
    return res.valid;
  },
  params: ['min', 'max'],
});

extend('digits_between', {
  async validate(value, { min, max }) {
    const res = await validate(value, `numeric|min:${min}|max:${max}`)
    return res.valid;
  },
  params: ['min', 'max'],
});

extend('number_halfwidth', {
  validate(value) {
    return /^[0-9]+$/.test(value)
  },
});


extend("decimal", {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'The {_field_} field must contain only decimal values'
});
extend('opening_time', {
  params: ['closing_time'],
  validate(value, {closing_time}) {
    return (Number(value) < Number(closing_time));
  },
});

extend('start_time', {
  params: ['end_time'],
  validate(value, {end_time}) {
    if (!end_time || end_time == 'all') {
      return  true;
    }
    return (Number(value) < Number(end_time));
  },
  message: '開始時間は終了時間以前の時間を入力してください。'
});

extend('start_time_and_date', {
  params: ['close_time', 'date_start', 'date_end'],
  validate(value, {close_time, date_start, date_end}) {
    if (date_start && date_end !== 'null' && close_time !== 'null' && close_time !== 'all') {
      const endDate = new Date(moment(`${date_end} ${close_time}`, 'YYYY-MM-DD HH:mm:ss').format());
      const startDate = new Date(moment(`${date_start} ${value}`, 'YYYY-MM-DD HH:mm:ss').format());
      if (Number(startDate) < Number(endDate)){
        return true;
      }
      return false;
    }
    if (value && (!close_time || close_time == 'all' || close_time == 'null')) {
      return  true;
    }
  },
  message: '開始時間は終了時間以前の時間を入力してください。'
});

extend('min_limit_booking_hour', {
  async validate(value, {min}) {
    const res = await validate(value, `min_value:${min}`)
    return res.valid;
  },
  params: ['min'],
});
extend('min_limit_booking_times', {
  async validate(value, {min}) {
    const res = await validate(value, `min_value:${min}`)
    return res.valid;
  },
  params: ['min'],
});

extend('end_date', {
  params: ['start_date'],
  validate(value, {start_date}) {
    if (!value) { return  true}
    const endDate = new Date(value);
    const startDate = new Date(start_date);
    return (startDate <= endDate);
  },
  message: '繰り返しは期限は日付以降の時間を入力してください。'
});

extend('price_min', {
  params: ['price_min'],
  validate(value, {price_min}) {
    if (!price_min || !value) {
      return true;
    }
    if (isNaN(price_min) === true || isNaN(value) === true) { return  true}
    return (Number(value) <= Number(price_min));
  },
  message: '価格下限は価格上限以下の値を入力してください。'
});

extend('from_date', {
  params: ['from_date'],
  validate(value, {from_date}) {
    if (!value) { return  true}
    const toDate = new Date(value);
    const fromDate = new Date(from_date);
    return (toDate <= fromDate);
  },
  message: '終了日は開始日以降の時間を入力してください。'
});

extend('from_date_list_page', {
  params: ['from_date'],
  validate(value, {from_date}) {
    if (!value || !from_date || value == 'null' || from_date == 'null') { return  true}
    const toDate = new Date(value);
    const fromDate = new Date(from_date);
    return (toDate <= fromDate);
  },
  message: '開始日は終了日以前の日付を入力してください。'
});


extend('from_date_time', {
  params: ['from_date_time'],
  validate(value, {from_date_time}) {
    if (!value || !from_date_time) { return  true}
    const toDate = new Date(value);
    const fromDate = new Date(from_date_time);
    return (toDate <= fromDate);
  },
  message: '注文日時の開始時間は注文日時の終了時間以前の時間を入力してください。'
});

extend('from_date_time_list_page', {
  params: ['from_date_time'],
  validate(value, {from_date_time}) {
    if (!value || !from_date_time || value == 'null' || from_date_time == 'null') { return  true}
    const toDate = new Date(value);
    const fromDate = new Date(from_date_time);
    return (toDate <= fromDate);
  },
  message: '開始日時は終了日時以前の日時を入力してください。'
});



extend('start_date', {
  params: ['start_date'],
  validate(value, {start_date}) {
    if (!value) { return  true}
    const toDate = new Date(value);
    const fromDate = new Date(start_date);
    return (toDate <= fromDate);
  },
  message: '終了日は開始日以降の時間を入力してください。'
});

extend('nullable_from_date', {
  params: ['nullable_from_date'],
  validate(value, {nullable_from_date}) {
    if (!value || nullable_from_date === 'null') { return  true}
    if (nullable_from_date) {
      const toDate = new Date(value);
      const fromDate = new Date(nullable_from_date);
      return (toDate <= fromDate);
    }
    return true;
  },
  message: '開始日は終了日以前の日付を入力してください。'
});

extend('min_max_number', {
  params: ['second_number'],
  validate(value, {second_number}) {
    if (!value || !second_number || value == 'undefined' || second_number== 'undefined') { return  true}

    return (Number(value) < Number(second_number));
  },
  message: '「空いている状況の上限数」フィールドに「やや混んでいる状況の上限数」の値未満の値を入力してください。'
});

extend('select_multi_validate', {
  params: ['params'],
  validate(value, params) {
    return !!(params && params.params);
  },
  message: '担当者を入力してください。'
});


extend('illegalInteger', {
  params: ['params'],
  validate(value) {
    return illegalInteger(value);
  },
  message: '数字を含み入力してください。'
});

extend('unsignedSmallInt', {
  params: ['params'],
  validate(value) {
    return  unsignedSmallInt(value);
  },
  message: '信号強度は32767以下の値を入力してください。'
});

extend('negativeSmallInt', {
  params: ['params'],
  validate(value) {
    return  negativeSmallInt(value);
  },
  message: '信号強度は-32768以上の値を入力してください。'
});

extend('hour_minute', {
  validate(value) {
    return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
  },
  // message: 'フリガナに使用できない文字があります。'
})

// const isUnique = (value) => {
//   return axios.post(this.ENDPOINT.UI_HELPER_VALIDATE_UNIQUE,
//     {
//     })
//     .then((response) => {
//     // Notice that we return an object containing both a valid property and a data property.
//     return {
//       valid: response.data.valid,
//       data: {
//         message: response.data.message
//       }
//     };
//   });
// };
//
// extend('unique', {
//   validate: isUnique,
//   message: 'not unique'
// })
