import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'categories/create',
    name: ROUTES.ADMIN.SERVICE_CATEGORY_CREATE,
    meta: {
      title: 'カテゴリ管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Service Category List',
          'href': '/admin/categories?perPage=99999&sortBy=apartment_id&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Service Category Create',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/service_category/ServiceCategoryCreateOrEdit"),
  },
  {
    path: 'categories/:id/edit',
    name: ROUTES.ADMIN.SERVICE_CATEGORY_EDIT,
    meta: {
      title: 'カテゴリ管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Service Category List',
          'href': '/admin/categories?perPage=99999&sortBy=apartment_id&filters.apartment_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Service Category Edit',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/service_category/ServiceCategoryCreateOrEdit"),
  },

];
