import _ from "lodash";
import { divide, round, ceil, multiply } from "mathjs";
import Cleave from "cleave.js";

export default {
  install(Vue, options) {

    Vue.directive('cleave', {
      inserted: (el, binding) => {
        var value = binding.value;
        el.cleave = new Cleave(el, value || {})
      },
      update: (el) => {
        const event = new Event('input', { bubbles: true });
        var delimiter = el.cleave.properties.delimiter;
        if (delimiter && delimiter === '-') {
          setTimeout(function () {
            var value = el.value;
            var cleaveRegex = el.getAttribute('cleave-regex');
            if (cleaveRegex && cleaveRegex.length && value && value.length) {
              value = value.replace(new RegExp(cleaveRegex, 'gm'), '');
            }
            el.cleave.setRawValue(value);
            el.value = el.cleave.properties.result
            // el.dispatchEvent(event)
          }, 0)
        } else {
          var value = el.value;
          var cleaveRegex = el.getAttribute('cleave-regex');
          if (cleaveRegex && cleaveRegex.length && value && value.length) {
            value = value.replace(new RegExp(cleaveRegex, 'gm'), '');
          }
          el.cleave.setRawValue(value);
          el.value = el.cleave.properties.result
          // el.dispatchEvent(event)
        }
      }
    })

    Vue.prototype._ = _
    Vue.prototype.$loading.appShow = (options = {}) => {
      try {
        $('.vld-overlay').remove();
      } catch (e) {
      }
      return Vue.prototype.$loading.show({
        loader: 'bars', opacity: 0.3, color: 'rgba(1, 92, 172, 1)', width: 96, height: 96, ...options
      })
    }
  }
}
