import axios from 'axios'
import Vue from 'vue';
import { AUTH } from "@constants";
import { HTTP_CODES, METHODS, ENDPOINT } from "@constants/api";
import { store } from '@store'
import { __redirect } from '@utils'

let noticeErrorLogout = true;

export default function ({ next }) {
  const app = new Vue;
  var authRole = store.state.auth.currentAuth.role;
  console.log(`Axios Interceptors [${authRole}]____`)
  let BASE_API_URL = AUTH[authRole].api_url;
  var LOGIN_NAME = AUTH[authRole].routes.login;
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    })

    failedQueue = [];
  }

  axios.interceptors.request.use(function (config) {
    let appToken = store.state.auth.currentAuth.token;
    if (!config.headers.Authorization && appToken) {
      config.headers['Authorization'] = 'Bearer ' + appToken;
    }
    config.baseURL = BASE_API_URL;
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const originalRequest = error.config;
    if ((error.response.status === HTTP_CODES.UNAUTHORIZED ||
      error.response.status === HTTP_CODES.FORBIDDEN) &&
      !originalRequest._retry) {

      if (error.response.data?.status === 'TOKEN_INVALID') {
        __redirect(LOGIN_NAME)
      }

      if (error.response.data?.status === 'TOKEN_EXPIRED') {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          }).then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          }).catch(err => {
            return Promise.reject(err);
          })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          var loader = app.$loading.appShow()
          axios({
            url: ENDPOINT.AUTH_REFRESH,
            method: METHODS.POST,
            baseURL: BASE_API_URL,
          }).then(response => {
            const token = response.headers.authorization;
            const expireTime = response.headers['token-expire-time'];
            store.commit('auth/updateAuth', {
              token,
              expire_time: expireTime
            })
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            processQueue(null, token);
            loader?.hide()
            isRefreshing = false
            resolve(axios(originalRequest));
          }).catch((err) => {
            loader?.hide()
            isRefreshing = false
            processQueue(err, null);
            reject(err);
            store.commit(`auth/removeAuth`)
            __redirect(LOGIN_NAME)
          })
        })
      }
      else if (error.response.data?.status === 'HTTP_FORBIDDEN') {
        store.commit(`auth/removeAuth`)
        __redirect(LOGIN_NAME)
      }
      else {
        if (noticeErrorLogout && error.response.data.action == 'LOGOUT_ACTION') {
          toastr.error(error.response.data.message, '');
          noticeErrorLogout = false;
        }
        store.commit(`auth/removeAuth`)
        return new Promise(function (resolve, reject) {
          loader?.hide()
          __redirect(LOGIN_NAME)
        }).then(() => {
          Promise.reject(error)
        })
      }
    }
    return Promise.reject(error);
  });
  app.$axios = axios;
  return next();
}
