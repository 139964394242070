import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";
import {CSV_DOWNLOAD_CONSTANT} from "@constants/csv_download";

export default [
  {
    path: 'csv_download',
    name: ROUTES.ADMIN.CSV_DOWNLOAD_CONSTANT,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.CSV Download',
        },
      ],
      activeKey: 'admin.csv_download'
    },
    component: () => import("@pages/admin/csv/DownloadCsv"),
  },
];
