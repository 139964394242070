import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'customer-vitals',
    name: ROUTES.ADMIN.CUSTOMER_VITAL_LIST,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer Vital List',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/customers/CustomerVitalListPage"),
  },
  {
    path: 'customer-vitals/create',
    name: ROUTES.ADMIN.CUSTOMER_VITAL_CREATE,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer Vital List',
          'href': '/admin/customer-vitals?filters.apartment_id.equal=all&filters.recorded_at.between=2022-03-30',
        },
        {
          'label': 'breadcrumbs.Customer Vital Create',
        },
      ],
      activeKey: 'admin.customer-vitals.create'
    },
    component: () => import("@pages/admin/customers/CustomerVitalCreateOrEdit"),
  },
  {
    path: 'customer-vitals/:id/edit',
    name: ROUTES.ADMIN.CUSTOMER_VITAL_EDIT,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer Vital List',
          'href': '/admin/customer-vitals?filters.apartment_id.equal=all&filters.recorded_at.between=2022-03-30',
        },
        {
          'label': 'breadcrumbs.Customer Vital Update',
        },
      ],
      activeKey: 'admin.customer-vitals.edit'
    },
    component: () => import("@pages/admin/customers/CustomerVitalCreateOrEdit"),
  },
];
