export const GENDER_CODE = [
  {
    id: 'all',
    name: "全て"
  },
  {
    id: 1,
    name: "男性"
  },
  {
    id: 2,
    name: "女性"
  },
]

export const CSV_DOWNLOAD_CONSTANT = {
  "gender_code" : GENDER_CODE,
  "TYPE_DOWNLOAD_CUSTOMER": 1,
  "TYPE_DOWNLOAD_INVOICE_FACILITY": 2,
  "TYPE_DOWNLOAD_INVOICE_UNION": 3,
  "TYPE_DOWNLOAD_INVOICE_DETAIL_FACILITY": 4,
  "TYPE_DOWNLOAD_INVOICE_DETAIL_UNION": 5
}

export const CSV_DOWNLOAD_TYPE_CONSTANT = {
  "BIZ_TYPE_FACILITY": 0,
  "BIZ_TYPE_UNION": 1
}
