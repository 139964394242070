import { ROUTES } from "@constants";

import authSettingMiddleware from "@routes/middlewares/authSettingMiddleware";
import axiosBaseNew from "@routes/middlewares/axiosBaseNew";
import authAccess from "@routes/middlewares/authAccess";
import rootRedirect from "@routes/middlewares/rootRedirect";
export default [
  {
    path: '/admin',
    meta: {
      middlewares: [authSettingMiddleware('admin'), axiosBaseNew],
    },
    component: () => import('@layouts/base_auths/AuthLayout'),
    children: [
      {
        path: 'login',
        name: ROUTES.ADMIN.AUTH_LOGIN,
        component: () => import("@pages/base_auths/LoginPage"),
      },
      {
        path: 'forgot-password',
        name: ROUTES.ADMIN.AUTH_FORGOT_PASSWORD,
        component: () => import("@pages/base_auths/ForgotPasswordPage"),
      },
      {
        path: 'reset-password',
        name: ROUTES.ADMIN.AUTH_RESET_PASSWORD,
        component: () => import("@pages/base_auths/ResetPasswordPage"),
      },
      {
        path: '/',
        meta: {
          middlewares: [authAccess, rootRedirect],
        },
      },
    ],
  }
]
