import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'restaurants',
    name: ROUTES.ADMIN.KYY_RESTAURANT_LIST,
    meta: {
      title: 'Restaurant',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterRestaurantList',
        },
      ],
      activeKey: 'admin.restaurant.list'
    },
    component: () => import("@pages/admin/kyy_restaurant/RestaurantListPage"),
  },
  {
    path: 'restaurants/create',
    name: ROUTES.ADMIN.KYY_RESTAURANT_CREATE,
    meta: {
      title: 'Restaurant',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterRestaurantList',
          'href': '/admin/restaurants',
        },
        {
          'label': 'breadcrumbs.MasterRestaurantCreate',
        },
      ],
      activeKey: 'admin.restaurant.create'
    },
    component: () => import("@pages/admin/kyy_restaurant/RestaurantCreateOrEdit"),
  },
  {
    path: 'restaurants/:id/edit',
    name: ROUTES.ADMIN.KYY_RESTAURANT_EDIT,
    meta: {
      title: 'Restaurant',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterRestaurantList',
          'href': '/admin/restaurants',
        },
        {
          'label': 'breadcrumbs.MasterRestaurantUpdate',
        },
      ],
      activeKey: 'admin.restaurant.edit'
    },
    component: () => import("@pages/admin/kyy_restaurant/RestaurantCreateOrEdit"),
  },
];
