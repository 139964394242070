import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'accounts',
    name: ROUTES.ADMIN.ALL_USERS_LIST,
    meta: {
      title: 'Quản lý',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Account List',
        },
      ],
      activeKey: 'admin.users.all_user'
    },
    component: () => import("@pages/admin/users/ListAllAccountPage"),
  },
  {
    path: 'accounts/create',
    name: ROUTES.ADMIN.ACCOUNT_CREATE,
    meta: {
      title: 'Quản lý Admin',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Account List',
          'href': '/admin/accounts?filters.apartment_id.equal=all&filters.status_enabled.equal=all',
        },
        {
          'label': 'breadcrumbs.Account Create',
        },
      ],
      activeKey: 'admin.accounts.create'
    },
    component: () => import("@pages/admin/users/UserCreateOrEdit"),
  },
  {
    path: 'accounts/:id/edit',
    name: ROUTES.ADMIN.ACCOUNT_EDIT,
    meta: {
      title: 'Quản lý Admin',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Account List',
          'href': '/admin/accounts?filters.apartment_id.equal=all&filters.status_enabled.equal=all',
        },
        {
          'label': 'breadcrumbs.Account Edit',
        },
      ],
      activeKey: 'admin.accounts.edit'
    },
    component: () => import("@pages/admin/users/UserCreateOrEdit"),
  },
  {
    path: 'accounts/:id/edit_password',
    name: ROUTES.ADMIN.ACCOUNT_EDIT_PASSWORD,
    meta: {
      title: 'Quản lý Admin',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Account List',
          'href': '/admin/accounts?filters.apartment_id.equal=all&filters.status_enabled.equal=all',
        },
        {
          'label': 'breadcrumbs.Account Edit',
        },
      ],
      activeKey: 'admin.accounts.edit_password'
    },
    component: () => import("@pages/admin/users/UserEditPassword"),
  },
];
