import Vue from 'vue';
import axios from 'axios';
import i18n from '@i18n';
import '@validate/vee-validate';
import moment from 'moment'
import router from '@routes';
import VueCookies from 'vue-cookies';
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import { localize } from "vee-validate";
import _ from 'lodash';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import globalVariable from "@utils/globalVariable";
import store from '@store';
import requestPlugin from '@utils/requestPlugin';
import mixins from "@mixins";
import App from './App.vue';

// OTHER PLUGIN
Vue.use(VueLoading);
Vue.use(VueCookies);
Vue.use(requestPlugin)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
App.i18n = i18n;
App.$cookies = VueCookies;
App.$localize = localize;
Vue.use(globalVariable);
Vue.prototype.$log = console.log;
Vue.prototype.moment = moment

// STORE
Vue.prototype.$store = store;

//REQUEST, ROUTER
Vue.prototype.$axios = axios
Vue.mixin(mixins);

new Vue({
    ...App,
    router,
    store,
    el: '#app'
});
