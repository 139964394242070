import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'cate-menus',
    name: ROUTES.ADMIN.KYY_CATEGORY_MENU_LIST,
    meta: {
      title: 'Category Menu',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCategoryMenuList',
        },
      ],
      activeKey: 'admin.cate-menus.list'
    },
    component: () => import("@pages/admin/kyy_category_menu/CategoryMenuListPage"),
  },
  {
    path: 'cate-menus/create',
    name: ROUTES.ADMIN.KYY_CATEGORY_MENU_CREATE,
    meta: {
      title: 'Category Menu',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCategoryMenuList',
          'href': '/admin/cate-menus',
        },
        {
          'label': 'breadcrumbs.MasterCategoryMenuCreate',
        },
      ],
      activeKey: 'admin.cate-menus.create'
    },
    component: () => import("@pages/admin/kyy_category_menu/CategoryMenuCreateOrEdit"),
  },
  {
    path: 'cate-menus/:id/edit',
    name: ROUTES.ADMIN.KYY_CATEGORY_MENU_EDIT,
    meta: {
      title: 'Category Menu',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCategoryMenuList',
          'href': '/admin/cate-menus',
        },
        {
          'label': 'breadcrumbs.MasterCategoryMenuUpdate',
        },
      ],
      activeKey: 'admin.cate-menus.edit'
    },
    component: () => import("@pages/admin/kyy_category_menu/CategoryMenuCreateOrEdit"),
  },
];
