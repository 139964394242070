<template>

  <div class="icheck-primary d-inline"
  :class="[isMarginLeft ? 'm-l-20' : '']">
    <input
      type="radio"
      :name="name"
      :checked="val == value"
      :value="val"
      :id="id ? id : uuid"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
    />
    <label :for="id ? id : uuid">{{ label }}</label>
    <slot name="extraField"></slot>
  </div>
</template>

<script>
import { v4 } from "uuid";

export default {
  name: "AppRadio",

  props: {
    isMarginLeft: false,
    id: {
      type: String,
    },

    name: {
      type: String,
    },

    value: {},

    val: {
      type: [Boolean, Number, String],
      required: true,
    },

    label: {
      type: String,
    },

    labelRight: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      uuid: v4(),
    };
  },
};
</script>
