import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'facility_plan/:facility_id/create',
    name: ROUTES.ADMIN.FACILITY_PLAN_CREATE,
    meta: {
      title: '館内施設プラン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Plan List',
          'href': '/admin/facilities',
        },
        {
          'label': 'breadcrumbs.Plan Create',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/plans/PlanCreateOrEdit"),
  },
  {
    path: 'facility_plan/:facility_id/list-page',
    name: ROUTES.ADMIN.FACILITY_PLAN_LIST,
    meta: {
      title: '館内施設プラン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Plan List',
        },
      ],
      activeKey: 'admin.customer-meals.create'
    },
    component: () => import("@pages/admin/plans/PlanListPage"),
  },
  {
    path: 'facility_plan/:facility_id/edit/:id',
    name: ROUTES.ADMIN.FACILITY_PLAN_EDIT,
    meta: {
      title: '館内施設プラン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Plan List',
          'href': '/admin/facility_plan/:facility_id/list-page',
        },
        {
          'label': 'breadcrumbs.Plan Edit',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/plans/PlanCreateOrEdit"),
  },
];
