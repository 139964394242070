

export const STATUS = [
  {
    id: 1,
    name: "有効"
  },
  {
    id: 0,
    name: "無効"
  },
]

export const PLAN_CONSTANT = {
  "status" : STATUS,
}
