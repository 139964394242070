import {__setLocalStorage, __getLocalStorage} from '@utils'
import {APP_VERSION_URL} from '@constants'
import Vue from "vue";

export default function ({next, to}) {
  console.log(`____App Middleware____`)
  let app = new Vue();
  /* when route change, app version will be detect then reload location) */
  const appVersionChange = __getLocalStorage('appVersionChange');
  const appVersion = __getLocalStorage('appVersion');
  if (!APP_VERSION_URL) {
    return next();
  }
  app.$request.get(APP_VERSION_URL, null, {noLoadingEffect: true}).then(res => {
    if (!res.hasErrors()) {
      let newVer = res.data;
      if (appVersion != newVer) {
        __setLocalStorage('appVersionChange', 'changed');
        __setLocalStorage('appVersion', newVer);
        console.log('___ app version NEW VERSION:' + newVer);
      }
    }
  })
  console.log('___ app version STATUS:' + appVersionChange + ' | LOCAL VERSION:' + appVersion)
  if (appVersionChange === 'changed') {
    __setLocalStorage('appVersionChange', 'not-changed');
    return window.location.replace(Vue.router.resolve({
      name: to.name,
      params: to.params,
      query: to.query
    }).href)
  }
  return next();
}
