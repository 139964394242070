import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'transport',
    name: ROUTES.ADMIN.TRANSPORTS_LIST,
    meta: {
      title: '送迎予約の一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Transports List',
        },
      ],
      activeKey: 'admin.master-booking.list'
    },
    component: () => import("@pages/admin/transports/TransportListPage"),
  },
  {
    path: 'transport-car-master/:id/edit',
    name: ROUTES.ADMIN.TRANSPORTS_EDIT,
    meta: {
      title: '送迎予約の一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Transports Edit',
        },
      ],
      activeKey: 'admin.master-booking.create'
    },
    component: () => import("@pages/admin/transports/TransportCreateOrEdit"),
  },
  {
    path: 'transport-car-master/create',
    name: ROUTES.ADMIN.TRANSPORTS_CREATE,
    meta: {
      title: '送迎予約の一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Transports Create',
        },
      ],
      activeKey: 'admin.master-booking.create'
    },
    component: () => import("@pages/admin/transports/TransportCreateOrEdit"),
  },
];
