import i18n from '@i18n'

export const STUDENT_INVOICE_STATUS = [
  {
    id: 1,
    name: i18n.t('student_invoice.status_options.not_sent'),
  },
  {
    id: 2,
    name:i18n.t('student_invoice.status_options.sent'),
  },
]

export const STUDENT_INVOICE_CONSTANT = {
  "STUDENT_INVOICE_STATUS" : STUDENT_INVOICE_STATUS,
}

