<template>
  <div>
    <app-modal-confirm
      ref="modal_delete_file"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteFile"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <div class="d-flex">
      <div class="preview">
        <img style="width: 100%; height: 150px" v-if="url" :src="url"/>
        <div v-else>
          {{$t('facilities.image')}}
        </div>
      </div>
      <div class="grid ml-3 mt-4" >
        <p class="btn btn-primary btn-image" @click="browse">{{$t('facilities.browse')}}</p>
        <p class="btn btn-danger btn-image" :class="[!url  ? 'btn-delete' : '']" @click="confirmDelete" v-if="deleteBtn">
          {{$t('common.delete')}}</p>
      </div>
    </div>
    <input
      style="display: none"
      type="file"
      :name="name"
      :disabled="disabled"
      v-on:change="preview($event)"
      ref="input_file"
      :accept="accept"
    />
  </div>
</template>

<script>
  export default {
    name: "AppFileInput",

    props: [
      "id",
      "name",
      "label",
      "type",
      "rules",
      "placeholder",
      "disabled",
      "autocomplete",
      "blur",
      "cleaveRegex",
      "extraLabel",
      "src",
      "imageSrc",
      "accept",
      "deleteBtn",
      "allowedExtension",
      "idEditing",
      "urlRemove"
    ],

    data() {
      return {
        vcleave: false,
        maxLength: false,
        minlength: false,
        url: null,
        value : null
      };
    },
    watch : {
      imageSrc: {
        // the callback will be called immediately after the start of the observation
        immediate: true,
        handler (val, oldVal) {
          this.url = val
        }
      }
    },
    mounted() {
      this.url = this.imageSrc;
    },
    methods: {
      onInput(event) {

      },
      validateFile(type) {
        for(let index in this.allowedExtension) {
          if(type == this.allowedExtension[index]) {
           return true;
          }
        }

        return false;
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },
      getWithHeight(file) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = function() {
            const { naturalWidth: width, naturalHeight: height } = img
            resolve({ width, height })
          }

          img.onerror = () => {
            reject('There was some problem with the image.')
          }
          img.src = file
        });
      },
      async preview(e) {
        if (!this.validateFile(e.target.files[0].type)) {
          this.__noticeError("最大5MBまでの JPEG, PNG 画像を指定してください。");
          return;
        }
        if ((e.target.files[0].size / (1024 * 1024) ) > 5) {
          this.__noticeError("最大5MBまでの JPEG, PNG 画像を指定してください。");
          return;
        }
        let file = e.target.files[0];
        let object = URL.createObjectURL(e.target.files[0])

        try {
          let fileInfo = await this.getWithHeight(object)
          if (!(fileInfo.width <= 1600 && fileInfo.height <= 900)) {
            this.__noticeError("縦900px、横1600pxにおさまるサイズの画像を指定してください。");
            return;
          }
        } catch (e) {
          this.__noticeError("最大5MBまでの JPEG, PNG 画像を指定してください。");
          return;
        }

        this.url = object;
        this.$emit('input', await this.getBase64(file))
      },
      browse(e) {
        e.preventDefault()
        this.$refs.input_file.click();
      },
      confirmDelete() {
        this.$refs.modal_delete_file.show();
      },
      async removeDataImage(e) {
        $(this.$refs.input_file)[0].value = ''
        if (this.idEditing) {
          const res = await this.$request.post(this.urlRemove);
          if (!res.hasErrors()) {
            this.$refs.modal_delete_file.hide();
            this.$emit('input', '');
            this.$emit('updated_at', res.data.data.data_updated_at)
            this.__noticeSuccess("正常に削除しました。");
          } else {
            this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          }
        } else {
          this.$refs.modal_delete_file.hide();
          this.$emit('input', '');
          this.__noticeSuccess("正常に削除しました。");
        }
      },
      deleteFile() {
        this.url = null
        this.removeDataImage();
      }
    }
  };
</script>
<style>
  .grid {
    display: grid;
  }

  .preview div {
    display: inline-block;
    border: solid 1px #ccc;
    text-align: center;
    padding: 40px;
    margin-bottom: 10px;
  }

  .btn-delete {
    display: none;
  }
  .btn-image {
    padding: 10px;
  }
</style>
