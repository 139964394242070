<template>
  <validation-provider
    :name="name"
    :rules="rules"
    v-slot="{ errors, invalid, validated }"
  >
    <div class="app-input-group-date">
      <input
        type="text"
        ref="datepicker"
        :value="value"
        readonly="readonly"
        :disabled="disabled"
        :class="[invalid && validated ? 'app-error' : '']"
        @input="$emit('input', $event.target.value)"
      />
      <span
        @click="trigger()"
        ref="iconDatepicker"
        class="text-dark bg-white"
      >
        <i class="fa fa-calendar"></i>
      </span>
    </div>
    <label v-if="invalid && validated" class="error">
      {{ errors[0] }}
    </label>
  </validation-provider>
</template>

<script>
export default {
  name: "AppDatepicker",

  props: [
    "options",
    "value",
    "name",
    "colLabel",
    "colInput",
    "label",
    "rules",
    "onChange",
    "value",
    "boundaryClass",
    "inputStyle",
    "labelClass",
    "disabled",
    "format"
  ],

  mounted: function () {
    let vm = this;
    $(this.$refs.datepicker).val(this.value);
    $(this.$refs.datepicker)
      .datepicker({
        autoclose: true,
        language: "ja",
        format: "yyyy-mm-dd",
        todayHighlight: true,
        weekStart: 1,
        ...this.options,
      })
      .on("change", function (ev, args) {
        vm.$emit("input", $(this).val());
      })
      .on("hide", function (e) {
        e.stopPropagation();
      });
  },

  watch: {
    /*on change value*/
    value: function (value, oldValue) {
      let vm = this;
      var formatDateValue = this.value
        ? this.__formatDate(this.value)
        : this.value;
      if (this.options && this.options.format === "yyyy/mm") {
        formatDateValue = this.value;
      }
      $(this.$refs.datepicker).val(formatDateValue).trigger("change");
      if (value !== oldValue) {
        this.onChange ? this.onChange(value) : "";
      }
      vm.$emit("change", formatDateValue);
    },

    /*on change options*/
    options: function (options) {},
  },

  methods: {
    trigger () {
      this.$refs.datepicker.focus();
    },
    handleIconDatepickerClick() {
      let vm = this;
      $(this.$refs.iconDatepicker)
        .datepicker({
          autoclose: true,
          language: "ja",
          format: "yyyy-mm-dd",
          todayHighlight: true,
          weekStart: 1,
          ...this.options,
        })
        .on("change", function (ev, args) {
          vm.$emit("input", $(this).val());
        })
        .on("hide", function (e) {
          e.stopPropagation();
        });
    },
  },

  destroyed: function () {
    $(this.$refs.datepicker).datepicker("destroy");
  },
};
</script>
