import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'order-exporter',
    name: ROUTES.ADMIN.KYY_ORDER_EXPORT,
    meta: {
      title: 'ExportOrder',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterExportOrder',
        },
      ],
      activeKey: 'admin.order_exporter'
    },
    component: () => import("@pages/admin/kyy_order_export/OrderExportPage"),
  },
  // {
  //   path: 'restaurants/:id/',
  //   name: ROUTES.ADMIN.KYY_RESTAURANT_EDIT,
  //   meta: {
  //     title: 'Restaurant',
  //     breadcrumb: [
  //       {
  //         'label': 'breadcrumbs.MasterRestaurantList',
  //         'href': '/admin/restaurants',
  //       },
  //       {
  //         'label': 'breadcrumbs.MasterRestaurantUpdate',
  //       },
  //     ],
  //     activeKey: 'admin.restaurant.edit'
  //   },
  //   component: () => import("@pages/admin/kyy_restaurant/RestaurantCreateOrEdit"),
  // },
];
