<template>
  <validation-provider
    :name="appKey ? `${name}-${appKey}` : name"
    :rules="rules"
    v-slot="{ errors, invalid, validated }"
  >
    <input
      v-if="vcleave"
      class="full-width app-input"
      :name="name"
      :id="id"
      :class="[invalid && validated ? 'app-error' : '']"
      :autocomplete="autocomplete || 'off'"
      :value="value"
      :disabled="disabled"
      :maxlength="maxLength"
      :minlength="minlength"
      :placeholder="placeholder"
      @blur="blur"
      v-cleave="vcleave"
      :cleave-regex="cleaveRegex"
      @input="onInput"
      @keydown="onKeyDown"
      @focusout="focusOut"
    />
    <input
      v-else
      class="full-width app-input"
      :name="name"
      :id="id"
      :class="[invalid && validated ? 'app-error' : '']"
      :autocomplete="autocomplete || 'off'"
      :value="value"
      :disabled="disabled"
      :maxlength="maxLength"
      :minlength="minlength"
      :placeholder="placeholder"
      @blur="blur"
      @input="onInput"
      @keydown="onKeyDown"
    />
    <label>{{extraLabel}}</label>
    <label v-if="invalid && validated" class="error" :for="id">
      {{ errors[0] }}
    </label>
  </validation-provider>
</template>

<script>
export default {
  name: "AppInput",

  props: [
    "id",
    "name",
    "label",
    "type",
    "rules",
    "value",
    "placeholder",
    "disabled",
    "autocomplete",
    "blur",
    "cleaveRegex",
    "extraLabel",
    "appKey"
  ],

  data() {
    return {
      vcleave: false,
      maxLength: false,
      minlength: false,
    };
  },

  mounted() {
    if (this.type && this.type.includes("number-length:")) {
      const length = this.type.split(":")[1];
      this.vcleave = {
        blocks: [parseInt(length)],
        numericOnly: true,
        moreRegex: '[^0-9]+$',
      };
      this.maxLength = length;
    }
    if (this.type && this.type.includes("string:")) {
      const length = this.type.split(":")[1];
      this.maxLength = length;
    }
    if (this.type && this.type.includes("string-uppercase-length:")) {
      const length = this.type.split(":")[1];
      this.vcleave = {
        blocks: [parseInt(length)],
        uppercase: true,
      };
    }
    switch (this.type) {
      case "bigint": {
        this.vcleave = {
          blocks: [17],
          numericOnly: true,
        };
        break;
      }
      case "int": {
        this.vcleave = {
          blocks: [8],
          numericOnly: true,
        };
        break;
      }
      case "phone": {
        this.vcleave = {
          blocks: [11],
          numericOnly: true,
          moreRegex: '[^0-9]+$',
        };
        this.maxLength = 11;
        break;
      }
      case "digits": {
        this.vcleave = {
          blocks: [191],
          numericOnly: true,
        };
        break;
      }
      case "time": {
        this.vcleave = {
          time: true,
          timePattern: ["h", "m"],
        };
        break;
      }
      case "email":
      case "string": {
        this.maxLength = 191;
        break;
      }
      case "string128": {
        this.maxLength = 128;
        break;
      }
      case "string512": {
        this.maxLength = 512;
        break;
      }
      case "number_format": {
        this.vcleave = {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        };
        break;
      }
      case "ja_postal_code": {
        this.vcleave = {
          blocks: [7],
          numericOnly: true,
          moreRegex: '[^0-9]+$',
          onValueChanged: function (e) {
            // e.target = { value: '5100-1234', rawValue: '51001234' }
            // console.log(e.target.rawValue, 'e.target.rawValue');
          }
        };
        this.minlength = 7;
        this.maxLength = 7;
        break;
      }
      case "double": {
        this.vcleave = {
          numeral: true,
          numeralThousandsGroupStyle: "none",
          numeralDecimalScale: 2,
          onValueChanged: function (event) {
            // e.target = { value: '5100-1234', rawValue: '51001234' }
            // console.log(e.target.rawValue, 'e.target.rawValue');
          }
        };
        break;
      }
    }
  },
  methods: {
    onInput (event) {
      let value = event.target.value;
      if (this.vcleave.moreRegex) {
        value = value.replace(new RegExp(this.vcleave.moreRegex, 'gm'), '');
        event.target.value = value;
      }
      // if (this.rules != undefined) {
      //   if (this.rules.includes('double42')) {
      //     if (parseFloat(event.target.value) > 99.99) {
      //       value = value.substring(0, value.length - 1);
      //       event.target.value = value;
      //     }
      //   }
      //
      //   if (this.rules.includes('double52')) {
      //     if (parseFloat(event.target.value) > 999.99) {
      //       value = value.substring(0, value.length - 1);
      //       event.target.value = value;
      //     }
      //   }
      // }

      if (this.type === "small-int") {
        let rg = /^-?\d{0,5}$/
        if (value.length > 0 && !rg.test(value)) {
          return;
        }
      }

      this.$emit('input', event.target.value)
      if (event.target.getAttribute("maxlength") != null && event.target.value.length > event.target.getAttribute("maxlength")) {
        event.target.blur()
      }
    },
    focusOut() {
      this.$emit('focusOutEvent')
    },
    onKeyDown(e) {
      if (this.rules != undefined) {
        if (e.keyCode != 8 && e.keyCode != 46 && e.keyCode != 37 && e.keyCode != 38 && e.keyCode != 39 && e.keyCode != 40) {
          let newValue = e.target.value;
          let valueArray = newValue.split('');
          const selectionLength = (e.target.selectionEnd - e.target.selectionStart);
          valueArray.splice(e.target.selectionStart, selectionLength, e.key);
          newValue = valueArray.join('');
          let  r = /^\d+(\.\d{0,5})?$/gi
          if (this.rules.includes('double42')) {
            if (e.keyCode == 32) {
              e.preventDefault();
            }
            if (!r.test(newValue) || parseFloat(newValue) > 99.99) {
              e.preventDefault();
            }
          }
          if (this.rules.includes('double52')) {
            if (e.keyCode == 32) {
              e.preventDefault();
            }
            if (!r.test(newValue) ||parseFloat(newValue) > 999.99) {
              e.preventDefault();
            }
          }

          if (this.type && this.type.includes('number-length')) {
            if (e.keyCode == 32 ) {
              e.preventDefault();
              return;
            }
          }

          if (this.type && this.type.includes('phone')) {
            if (e.keyCode == 32) {
              e.preventDefault();
              return;
            }
          }
        }
      }
    }
  }
};
</script>
