import {ROUTES} from "@constants"

export default [
  {
    path: 'invoice_receipt',
    name: ROUTES.ADMIN.INVOICE_RECEIPT,
    meta: {
      title: '請求書・領収書アップロード',
      breadcrumb: [
        {
          'label': '請求書・領収書一覧',
          'href': '/admin/invoice_receipt_list',
        },
        {
          'label': '請求書・領収書アップロード',
        },
      ],
      activeKey: 'admin.users.all_user'
    },
    component: () => import("@pages/admin/invoice/InvoiceReceipt"),
  },

  {
    path: 'invoice_receipt_list',
    name: ROUTES.ADMIN.INVOICE_RECEIPT_LIST,
    meta: {
      title: '請求書・領収書一覧',
      breadcrumb: [
        {
          'label': '請求書・領収書一覧',
        },
      ],
      activeKey: 'admin.users.all_user'
    },
    component: () => import("@pages/admin/invoice/InvoiceReceiptListPage"),
  },
];
