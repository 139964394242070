<template>
  <div>
    <template v-if="$slots.filters">
      <div class="card card-transparent">
        <div class="card-header search-container">
          <div class="search-content">
            <validation-observer v-slot="{ passes, valid, validated }">
              <form
                novalidate
                autocomplete="off"
                @submit.prevent="passes(searchHandle)"
              >
                <slot name="filters"></slot>
                <div class="m-t-10 row justify-content-center">
                  <button
                    @click="resetAllSearch"
                    class="btn btn-default m-r-15"
                    type="button"
                  >
                    {{ $t("common.reset") }}
                  </button>
                  <button
                    :disabled="!valid && validated"
                    class="btn btn-primary text-complete"
                    type="submit"
                  >
                    {{ $t("common.search") }}
                  </button>
                  <slot name="extra_filters_btn"></slot>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </template>
    <div
      class="app-nav-table d-flex mb-3"
    >
      <div class="app-paginate-title align-self-end">
        <slot name="table-title">
          <div
            class="dataTables_info app-text"
            style="text-transform: lowercase"
            role="status"
            aria-live="polite"
          >
            <template v-if="$cookies.get(APP_NAME + 'lang') == 'en'">
              <span style="text-transform: uppercase">S</span>howing
              {{ meta.from }} 〜 {{ meta.to }} of {{ meta.total }}
              records
            </template>
            <template v-else>
              Tổng {{ meta.total }} bản ghi. Đang hiển thị từ bản ghi {{ meta.from }} đến {{ meta.to }}
            </template>
          </div>
        </slot>
      </div>
      <div class="export-options-container">
        <slot name="table-menu-center"></slot>
      </div>

      <div class="export-options-container ml-auto">
        <slot name="table-menu-right"></slot>
      </div>
    </div>
    <div class="no-footer bg-white app-table-container">
      <div class="table-responsive">
        <table class="table app-table-list">
          <thead class="thead-light">
          <tr>
<!--            <th class="text-center cell-fit-content">ID</th>-->
            <template v-for="(column, index) in columns">
              <slot :name="`header-cell-${column.name}`">
                <th
                  :key="index"
                  v-if="column.sortable"
                  @click="toggleSortBy(column.name)"
                  :class="[
                      pagination.sortBy === column.name
                        ? pagination.descending
                          ? 'app-sort-desc'
                          : 'app-sort-asc'
                        : 'app-sort',
                      '',
                      column.fitSize ? 'cell-fit-content' : '',
                    ]"
                >
                  {{ column.label }}
                  <i v-if="pagination.sortBy === column.name && pagination.descending" class="fas fa-sort-down" style="vertical-align: inherit;"></i>
                  <i v-if="pagination.sortBy === column.name && !pagination.descending" class="fas fa-sort-up" style="vertical-align: middle;"></i>

                </th>
                <th
                  v-else
                  :key="index"
                  :class="[column.fitSize ? 'cell-fit-content' : '']"
                >
                  {{ column.label }}
                </th>
              </slot>
            </template>
          </tr>
          </thead>
          <tbody  id="appTableList">
            <tr v-if="!entries.length">
              <td colspan="100">
                <span v-if="loading">
                  {{ $t("common.loading") }}
                </span>
                <span v-else>
                  {{ $t("common.list empty") }}
                </span>
              </td>
            </tr>
            <tr :key="entry.id" :id="entry.id" :data-id="entry.id" v-for="(entry, entryIndex) in entries">
  <!--            <slot name="body-cell-no" :row="entry">-->
  <!--              <td class="app-align-middle text-center">-->
  <!--                {{ entry.no }}-->
  <!--              </td>-->
  <!--            </slot>-->

              <slot
                v-for="(column, columnIndex) in columns"
                :name="`body-cell-${column.name}`"
                :row="entry"
              >
                <td
                  :class="['app-align-middle', column.textAlign ? column.textAlign : 'text-left', column.class]"
                  :key="columnIndex"
                >
                  <p
                    :data-original-title="_.get(entry, column.name)"
                    :class="['app-table-p app-cell-tooltip mb-0', checkLineBreak(_.get(entry, column.name))]"
                  >{{ _.get(entry, column.name) }}
                  </p>
                </td>
              </slot>
            </tr>
          </tbody>
          <slot name="table-footer"></slot>
        </table>
      </div>
      <div class="app-table-footer">
  <!--          <div class="app-page-number" v-show="parseInt(meta.total) > 5">-->
  <!--            <div class="app-title">{{ $t("common.perpage") }}</div>-->
  <!--            <select-->
  <!--              class="app-input m-l-10"-->
  <!--              @change="changePerPage"-->
  <!--              v-model="pagination.currentPerPage"-->
  <!--            >-->
  <!--              <option-->
  <!--                :key="perpageIndex"-->
  <!--                v-for="(perpage, perpageIndex) in pagination.perPages"-->
  <!--                :value="perpage"-->
  <!--              >-->
  <!--                {{ perpage }}-->
  <!--              </option>-->
  <!--            </select>-->
  <!--          </div>-->
        <div class="dataTables_paginate app-paginate-container">
          <app-paginate
            v-show="parseInt(meta.last_page) > 1"
            ref="paginate"
            :page-count="parseInt(meta.last_page)"
            :page-range="5"
            :margin-pages="2"
            :click-handler="clickPagination"
            prev-text="<"
            next-text=">"
            breakViewClass="paginate-break"
            prev-class="paginate-button-prev"
            next-class="paginate-button-next"
            :force-page="parseInt(pagination.currentPage)"
            :hide-prev-next="true"
            :page-class="'paginate-button'"
          >
          </app-paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {ROUTES} from "../../../constants";

const EVENT_PAGE_CHANGE = 'EVENT_PAGE_CHANGE';
const EVENT_SORT_BY = 'EVENT_SORT_BY';
const EVENT_SEARCH = 'EVENT_SEARCH';
const EVENT_PER_PAGE_CHANGE = 'EVENT_PER_PAGE_CHANGE';

// Default SortableJS
import Sortable from 'sortablejs';

export default {
  name: "AppBasicTable",
  props: {
    settingColumns: Array,
    filters: {
      type: Object,
      default: {},
    },
    autoFilters: {
      type: Boolean,
      default: true
    },
    tableName: String,
    endpointList: String,
    beforeFetch: String,
    paramsBefore: {
      type: Object
    },
    listCanSort: false,
  },

  data() {
    const columns = this.settingColumns.map(val => {
      return {
        sortable: false,
        editable: true,
        label: val.label === undefined ? val.name.charAt(0).toUpperCase() + val.name.slice(1) : val.label,
        ...val
      }
    })
    var cloneFilters = _.cloneDeep(this.filters);
    _.forEach(this.$route.query, (value, columnString) => {
      if (_.includes(columnString, 'filters.')) {
        let splited = columnString.split('.');
        let name = splited[1];
        let condition = splited[2];
        _.forEach(cloneFilters, (filter, index) => {
          if (filter.name === name && filter.condition === condition) {
            cloneFilters[index] = {...cloneFilters[index], value: value}
          }
        })
      }
    })
    let {page, search, sortBy, sortType, perPage} = this.$route.query;
    return {
      columns: columns,
      entriesRes: {},
      entries: [],
      loading: true,
      pagination: {
        currentPage: page ? page : 1,
        search: search ? search : "",
        sortBy: sortBy ? sortBy : null,
        descending: sortType === "desc",
        currentPerPage: perPage ? perPage : 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
        filters: cloneFilters,
      },
      meta: {},
      defaultQuery: this.$route.query,
      sortTable: null,
    };
  },

  watch: {
    "$route.query": {
      handler: async function (after, before) {
        if (!_.isEqual(after, before) && this.autoFilters === true) {
          await this.setSearchStateByRouteQuery()
          this.getEntries(false)
        }
      },
      deep: true,
    },
  },
  computed: {
    hasFiltersUi() {
      var hasFiltersUi = false;
      _.forEach(this.filters, filter => {
        if (filter.uiType) {
          hasFiltersUi = true;
        }
      })
      return hasFiltersUi;
    },
  },
  mounted() {
    this.getEntries(true)
    $(".tooltip").tooltip("hide");
  },
  methods: {
    checkLineBreak(note) {
      try {
        if (note) {
          if (note.match(/\n/g)) {
            return 'text-break';
          }
        }
      } catch(err) {
        return ''
      }
    },
    clickPagination(selectPage) {
      if (selectPage !== this.pagination.currentPage) {
        this.setCurrentPage(selectPage)
        this.updateRouteQuery(EVENT_PAGE_CHANGE)
      }
    },
    changePerPage() {
      this.setCurrentPage(1)
      this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_PER_PAGE_CHANGE])
    },
    setCurrentPage(page) {
      this.pagination.currentPage = page;
      if (this.$refs.paginate) {
        this.$refs.paginate.handlePageSelected(page);
      }
    },
    searchHandle() {
      this.setCurrentPage(1)
      this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_SEARCH])
      this.$emit('searched')
    },
    async getEntries(firstLoadPage) {
      await this.setSearchStateByRouteQuery(firstLoadPage)
      const {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      let params = {
        page: currentPage,
        perPage: currentPerPage,
        search: search,
        sortBy: sortBy,
        sortType: descending ? 'desc' : 'asc',
      }
      const filtersClone = _.cloneDeep(this.filters)
      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length && filter.value.toString() != 'all') {
          params[`filters[${filter.name}][${filter.condition}]`] = filter.value
        }
      })

      let endpoint = this.endpointList;
      if(this.beforeFetch){
        let response = await this.$request.get(this.beforeFetch, this.paramsBefore);
        this.$emit('beforeFetch', response);
      }

      const res = await this.$request.get(endpoint, {...params})
      if (!res.hasErrors()) {
        this.entriesRes = _.cloneDeep(res);
        this.entries = res.data.data.map((entry, index) => {
          return {
            ...entry, no: (index + 1 + (this.pagination.currentPage - 1) * this.pagination.currentPerPage)
          }
        })
        this.meta = res.data.meta
        if (!this.meta.from) {
          this.meta.from = 0;
        }
        if (!this.meta.to) {
          this.meta.to = 0;
        }
        if (this.pagination.currentPage > 1 && this.entries.length === 0) {
          this.setCurrentPage(res.data.meta.last_page)
          this.updateRouteQuery(EVENT_PAGE_CHANGE)
        }
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        if (this.pagination.currentPage > 1 && this.entries.length === 0) {
          this.setCurrentPage(1)
          this.updateRouteQuery(EVENT_PAGE_CHANGE)
        }
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          // Count number of paragraph
          let paras = $(el).text().split('\n');
          let count = 0;
          $.each(paras, (index, value) => {
            count++;
          });

          // Show tooltip
          if ((count >= 3) || (el.offsetWidth < el.scrollWidth)) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
      this.dragDropTable()
      this.loading = false;
    },
    async setSearchStateByRouteQuery(firstLoadPage) {
      let {page, search, sortBy, sortType, perPage} = this.$route.query;

      if (firstLoadPage && this.filters.default) {
        if (sortBy == null && this.filters.default.sortBy) {
          sortBy = this.filters.default.sortBy
        }
        if (sortType == null && this.filters.default.sortType) {
          sortType = this.filters.default.sortType
        }
      }

      this.pagination = {
        currentPage: page ? page : 1,
        search: search ? search : '',
        sortBy: sortBy ? sortBy : null,
        descending: sortType === 'desc',
        currentPerPage: perPage ? perPage : 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      }
      var newFilters = _.cloneDeep(this.filters)
      _.forEach(newFilters, (filter, key) => {
        newFilters[key] = {...filter, value: null}
      })
      _.forEach(this.$route.query, (value, columnString) => {
        if (_.includes(columnString, 'filters.')) {
          let splited = columnString.split('.');
          let name = splited[1];
          let condition = splited[2];
          _.forEach(newFilters, (filter, key) => {
            if (filter.name === name && filter.condition === condition) {
              newFilters[key] = {...newFilters[key], value: value}
            }
          })
        }
      })
      await this.$emit('update:filters', newFilters)
    },
    async resetAllSearch() {
      this.pagination.search = ''
      this.setCurrentPage(1);
      if (Object.keys(this.$route.query).length !== 0 && !_.isEqual(this.$route.query, this.defaultQuery)) {
       await this.$router.push({name: this.$route.name, query: this.defaultQuery})
      } else {
        await this.$emit('resetAllSearch')
        await this.getEntries(false)
      }
      await this.$emit('resetAllSearch')
    },
    toggleSortBy(columnName) {
      if (this.pagination.sortBy !== columnName) {
        this.pagination.sortBy = columnName
        this.pagination.descending = false;
      } else {
        if (!this.pagination.descending) {
          this.pagination.sortBy = columnName
          this.pagination.descending = true;
        } else {
          this.pagination.sortBy = null
          this.pagination.descending = null;
        }
      }
      this.updateRouteQuery(EVENT_SORT_BY);
    },
    updateRouteQuery(events) {
      if (!_.isArray(events)) {
        events = [events]
      }
      var queryObj = _.clone(this.$route.query);
      var {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      _.forEach(events, event => {
        if (event === EVENT_PAGE_CHANGE) {
          if (currentPage > 1) {
            queryObj['page'] = currentPage
          } else {
            delete queryObj['page']
          }
        }
        if (event === EVENT_PER_PAGE_CHANGE) {
          if (parseInt(currentPerPage) !== 10) {
            queryObj['perPage'] = currentPerPage;
          } else {
            delete queryObj['perPage']
          }
        }
        if (event === EVENT_SORT_BY) {
          if (sortBy && sortBy.length) {
            queryObj['sortBy'] = sortBy;
          } else {
            delete queryObj['sortBy'];
          }
          if (sortBy && sortBy.length) {
            queryObj['sortType'] = descending ? 'desc' : 'asc';
          } else {
            delete queryObj['sortType'];
          }
        }
        if (event === EVENT_SEARCH) {
          if (search && search.length) {
            queryObj['search'] = search;
          } else {
            delete queryObj['search'];
          }
          const filters = _.cloneDeep(this.filters);
          _.forEach(filters, (filter) => {
            if (filter.value && filter.value.toString().length) {
              queryObj['filters.' + filter.name + '.' + filter.condition] = this.getString(filter.value).trim()
            } else {
              delete queryObj['filters.' + filter.name + '.' + filter.condition];
            }
          })
        }
      })
      if (!_.isEqual(queryObj, this.$route.query)) {
        this.$router.push({query: queryObj})
      } else {
        this.getEntries(false)
      }
    },
    dragDropTable() {
      var self = this;
      let canSort = self.$route.query.hasOwnProperty('filters.apartment_id.equal') && self.$route.query['filters.apartment_id.equal'] != 'all';
      if (self.sortTable) {
        self.sortTable.destroy();
      }
      if (self.listCanSort && canSort) {
        self.sortTable = new Sortable.create(appTableList, {
          // Element dragging ended
          sort: canSort,
          onEnd: function (/**Event*/evt) {
            if (canSort) {
              let to = evt.to.querySelectorAll('tr');
              let sortType = self.$route.query.sortType;
              const sortIndex = []
              $.map(to, function (el){
                sortIndex.push({
                  position: sortType == 'desc' ? to.length - ($(el).index()) : $(el).index() + 1,
                  id: $(el).data('id')
                })
              });
              self.$emit('sortRowTable', sortIndex);
            } else {
              let msgError = '';
              if (self.$route.name == ROUTES.ADMIN.FACILITY_LIST) {
                msgError = '館内施設を並び替えるにはマンションを指定してください。';
              } else if (self.$route.name == ROUTES.ADMIN.CATEGORY_LIST) {
                msgError = 'カテゴリを並び替えるにはマンションを指定してください。'
              }
              self.__noticeError(msgError);
            }
          },
        });
      }

    },
    getString(val){
      if (typeof val === 'string' || val instanceof String){
        return val
      }
      return val.toString()
    }
  }
};
</script>

<style>
  .app-table-container p.app-table-p.text-break {
    line-height: 22px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    .app-table-container .app-table-list {
      overflow-y: scroll;
      max-height: 500px;
      display: block;
    }
  }
</style>
