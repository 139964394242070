<template>
  <div
    :class="['modal fade', modalClass]"
    ref="modal"
    role="dialog"
    data-backdrop="static"
    aria-hidden="true"
  >
    <div :class="['modal-dialog', ' modal-' + size]">
      <div class="modal-content-wrapper">
        <div class="modal-content">
          <slot name="header">
            <div class="modal-header clearfix text-left">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                @click="$emit('close')"
              >
                x
<!--                <i class="pg-close fs-14"></i>-->
              </button>
              <span class="title">{{ modalHeaderTitle }}</span>
            </div>
          </slot>
          <slot name="container">
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div v-if="$slots.footer" class="modal-footer justify-content-between">
              <slot name="footer"></slot>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppModal",

  props: {
    modalClass: String,
    modalHeaderTitle: String,
    name: String, // unique string
    size: String, //md, lg, sm,
    onHide: Function | Promise,
  },

  data() {
    return {
      isShow: false,
    };
  },

  mounted() {
    if (this.isShow) {
      $(this.$refs.modal).modal("show");
    } else {
      $(this.$refs.modal).modal("hide");
    }

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.isShow = false;
      if (this.onHide) {
        this.onHide();
      }
    });

    $(this.$refs.modal).on("isShow.bs.modal", () => {
      this.isShow = true;
    });
  },

  watch: {
    isShow: function (isShow) {
      if (isShow) {
        $(this.$refs.modal).modal("show");
      } else {
        $(this.$refs.modal).modal("hide");
      }
    },
  },

  methods: {
    hide() {
      this.isShow = false;
    },

    show() {
      this.isShow = true;
    },
  },

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  },
};
</script>
