import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'gateways/create',
    name: ROUTES.ADMIN.GATEWAY_CREATE,
    meta: {
      title: 'ゲートウェイ管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Gateway List',
          'href': '/admin/gateways?filters.status_enabled.equal=all&filters.apartment_id_equal.equal=all&filters.facility_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Gateway Create',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/gateways/GatewayCreateOrEdit"),
  },
  {
    path: 'gateways/:id/edit',
    name: ROUTES.ADMIN.GATEWAY_EDIT,
    meta: {
      title: 'ゲートウェイ管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Gateway List',
          'href': '/admin/gateways?filters.status_enabled.equal=all&filters.apartment_id_equal.equal=all&filters.facility_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Gateway Edit',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/gateways/GatewayCreateOrEdit"),
  },
  {
    path: 'gateways-history/:id/edit',
    name: ROUTES.ADMIN.GATEWAY_HISTORY_LIST,
    meta: {
      title: 'ゲートウェイ管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Gateway List',
          'href': '/admin/gateways?filters.status_enabled.equal=all&filters.apartment_id_equal.equal=all&filters.facility_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Gateway History',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/gateways/GatewayHistoryListPage"),
  },
];
