<template>
  <app-modal
    ref="modal"
    modal-header-title="会員一覧から選択"
    size="lg"

  >
    <template v-slot:header>
      <div class="modal-header clearfix text-left">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
          @click="handlerClose()"
        >
          x
        </button>
        <span class="title">会員一覧から選択</span>
      </div>
    </template>
    <template v-slot:container>
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="endPoint"
      >
        <template v-slot:filters>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ filters.name_kana_like.label }}</label>
                <app-input :name="filters.name_kana_like.name" input-style="normal"
                           v-model="filters.name_kana_like.value"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ filters.email_like.label }}</label>
                <app-input :name="filters.email_like.name" input-style="normal"
                           v-model="filters.email_like.value"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ filters.name_like.label }}</label>
                <app-input :name="filters.name_like.name" input-style="normal"
                           v-model="filters.name_like.value"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:body-cell-select="props">
          <td class="app-align-middle text-center app-p-none">
            <button
              class="btn btn-primary"
              @click="handlerSelectCustomer(props)"
            >
              {{ $t("common.select_customer") }}
            </button>
          </td>
        </template>
      </app-basic-table>
    </template>
  </app-modal>
</template>

<script>
  import AppModal from "../../../components/_common/popup/AppModal";
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import {ENDPOINT} from "../../../constants/api";

  export default {
    name: "AppModalCustomer",
    components: {
      AppModal,
      "app-basic-table": AppBasicTable,
    },
    props : [
      "endPoint",
      "idEditing",
      "screenFlag", // meal is 0, vital is 1, reservation is 2
      "date"
    ],
    methods: {
      hide() {
        this.$refs.modal.hide();
      },

      show() {
        this.$refs.modal.show();
        // Refresh table data
        this.$refs.basic_table.getEntries();
      },
      handlerSelectCustomer(data) {
        this.$emit('selectCustomer', data);
        this.redirectDefaultValue()
      },
      handlerClose() {
        this.redirectDefaultValue()
      },

      async redirectDefaultValue() {
        if (this.idEditing) {
          if (this.screenFlag == 1) {
            //is vital
            await this.$router.push({
              name: this.ROUTES.ADMIN.CUSTOMER_VITAL_EDIT, query: {}
            }).catch(e => {
              console.log('has error with list customer edit vital screen');
            });
          } else if (this.screenFlag == 0) {
            //is meal
            await this.$router.push({
              name: this.ROUTES.ADMIN.CUSTOMER_MEAL_EDIT, query: {}
            }).catch(e => {
              console.log('has error with list customer edit meal screen');
            });
          } else if (this.screenFlag == 2) {
            //is reservation
            let queryReservation;
            if (this.date) {
              queryReservation = {date:this.date};
            } else {
              queryReservation = {};
            }
            await this.$router.push({
              name: this.ROUTES.ADMIN.RESERVATION_EDIT, query: queryReservation
            }).catch(e => {
              console.log('has error with list customer edit reservation screen');
            });
          }
          else if (this.screenFlag == 3) {
            //is reservation
            await this.$router.push({
              name: this.ROUTES.ADMIN.ROOMSERVICE_ORDER_EDIT, query: {}
            }).catch(e => {
              console.log('has error with list customer edit roomservice order create screen');
            });
          }
        } else {
          if (this.screenFlag == 1) {
            //is vital
            await this.$router.push({
              name: this.ROUTES.ADMIN.CUSTOMER_VITAL_CREATE, query: {}
            }).catch(e => {
              console.log('has error with list customer create vital screen');
            });
          } else if (this.screenFlag == 0) {
            //is meal
            await this.$router.push({
              name: this.ROUTES.ADMIN.CUSTOMER_MEAL_CREATE, query: {}
            }).catch(e => {
              console.log('has error with list customer create meal screen');
            });
          } else if (this.screenFlag == 2) {
            //is reservation
            let queryReservation;
            if (this.date) {
              queryReservation = {date:this.date};
            } else {
              queryReservation = {};
            }
            await this.$router.push({
              name: this.ROUTES.ADMIN.RESERVATION_CREATE, query: queryReservation
            }).catch(e => {
              console.log('has error with list customer create reservation screen');
            });
          } else if (this.screenFlag == 3) {
            //is reservation
            await this.$router.push({
              name: this.ROUTES.ADMIN.ROOMSERVICE_ORDER_CREATE, query: {}
            }).catch(e => {
              console.log('has error with list customer create reservation screen');
            });
          }
        }
      },
    },
    data() {
      return {
        filters: {
          email_like: {
            name: "email",
            condition: "like",
            label: this.$t("common.email")
          },
          name_kana_like: {
            name: "search_kana_name",
            condition: "like",
            label: this.$t("common.name_kana")
          },
          name_like: {
            name: "search_name",
            condition: "like",
            label: this.$t("common.name")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), sortable: true},
          {name: "email", label: this.$t("common.email"), sortable: true},
          {name: "name", label: this.$t("common.name")},
          {name: "name_kana", label: this.$t("common.name_kana")},
          {name: "select", label: this.$t("common.select_customer"), textAlign: 'text-center'},
        ],
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>
