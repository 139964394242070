import Vue from "vue";
import { AUTH } from "@constants";
import { ENDPOINT } from "@constants/api";
import { store } from '@store'
import { __redirect } from '@utils'

export default function ({ next }) {
  console.log('____AdminAuthenticated____')
  let user = store.getters["auth/getUser"];
  let expireTime = store.state.auth.currentAuth.expire_time;
  let role = store.state.auth.currentAuth.role;
  if (user && user.role === AUTH[role].role_id) {
    if (expireTime <= moment().valueOf()) {
      return Vue.prototype.$request.get(ENDPOINT.PROFILE_USER).then(() => {
        next()
      }).catch(() => {
      })
    } else {
      return next()
    }
  } else {
    __redirect(AUTH[role].routes.login)
  }
}
