import adminRoute from './admin';
import NotFound404Page from '@pages/errors/NotFound404Page.vue';
import LinkExpiredPage from '@pages/errors/LinkExpiredPage';
import {ROUTES} from "@constants";
let routes = [
  ...adminRoute,

  {
    path: '*',
    name: '404',
    component: NotFound404Page
  },

  {
    path: '/not-found-page',
    name: 'not-found',
    component: NotFound404Page
  },

  {
    path: '/link-expired',
    name: 'link-expired',
    component: LinkExpiredPage
  },
  {
    path: '/restaurants/qr_code_table/:id',
    name: ROUTES.ADMIN.RESTAURANTS_QR_CODE,
    component: () => import("@pages/admin/restaurants/QrCode"),
  },
  {
    path: '/admin/verify-resend-email',
    name: 'ACCOUNT_VERIFY_RESEND_EMAIL',
    component: () => import("@pages/admin/users/UserVerifyResendEmail"),
  },
  {
    path: '/admin/verify-email',
    name: 'ACCOUNT_VERIFY_EMAIL',
    component: () => import("@pages/admin/users/UserVerifyEmail"),
  },
  {
    path: '/admin/verify-password',
    name: 'ACCOUNT_VERIFY_PASSWORD',
    component: () => import("@pages/admin/users/UserVerifyPassword"),
  },
  {
    path: '/admin/verify-success',
    name: 'VERIFY_SUCCESS',
    component: () => import("@pages/admin/users/VerifySuccess"),
  },
  {
    path: '/admin/verify-false',
    name: 'VERIFY_FALSE',
    component: () => import("@pages/admin/users/VerifyFalse"),
  },
]

export default routes;
