<template>
  <router-view/>
</template>

<script>
export default {
  watch: {
    '$route'(to, from) {
      // document.title = to.meta?.title || 'ProjectNameCamelCase';
    },
  },
}
</script>
<style lang="scss">
@import "@assets/app.scss";
</style>
