export const FILTER_STATUS_BOOKING = [
  {
    id : 'all',
    name : "全て"
  },
  {
    id: 0,
    name: "予約済",
  },
  {
    id: 2,
    name: "キャンセル済",
  },
  {
    id: 1,
    name: "送迎済",
  },
]

export const STATUS_BOOKING = [
  {
    id: 0,
    name: "予約済",
  },
  {
    id: 2,
    name: "キャンセル済",
  },
  {
    id: 1,
    name: "送迎済",
  },
]

export const TRANSPORTS_LIST_CONSTANT = {
  "FILTER_STATUS_BOOKING" : FILTER_STATUS_BOOKING,
  "STATUS_BOOKING" : STATUS_BOOKING
}

