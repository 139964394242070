import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'customer-meals',
    name: ROUTES.ADMIN.CUSTOMER_MEAL_LIST,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer Meals List',
        },
      ],
      activeKey: 'admin.customer-meals.list'
    },
    component: () => import("@pages/admin/customers/CustomerMealListPage"),
  },
  {
    path: 'customer-meals/create',
    name: ROUTES.ADMIN.CUSTOMER_MEAL_CREATE,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer Meals List',
          'href': '/admin/customer-meals?filters.date_equal.between=2022-03-30',
        },
        {
          'label': 'breadcrumbs.Customer Meals Create',
        },
      ],
      activeKey: 'admin.customer-meals.create'
    },
    component: () => import("@pages/admin/customers/CustomerMealCreateOrEdit"),
  },
  {
    path: 'customer-meals/:type/:id/edit',
    name: ROUTES.ADMIN.CUSTOMER_MEAL_EDIT,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Customer Meals List',
          'href': '/admin/customer-meals?filters.date_equal.between=2022-03-30',
        },
        {
          'label': 'breadcrumbs.Customer Meals Edit',
        },
      ],
      activeKey: 'admin.customer-meals.create'
    },
    component: () => import("@pages/admin/customers/CustomerMealCreateOrEdit"),
  },
];
