import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'sale',
    name: ROUTES.ADMIN.SALE_LIST,
    meta: {
      title: '売上一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Sale List',
        },
      ],
      activeKey: 'admin.sale.list'
    },
    component: () => import("@pages/admin/sale/SaleListPage"),
  },
  {
    path: 'sale/list_detail',
    name: ROUTES.ADMIN.SALE_LIST_DETAIL,
    meta: {
      title: '売上一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Sale List',
        },
      ],
      activeKey: 'admin.sale.list'
    },
    component: () => import("@pages/admin/sale/SaleListPageDetail"),
  },
  {
    path: 'sale/detail/apartment',
    name: ROUTES.ADMIN.SALE_LIST_DETAIL_APARTMENT,
    meta: {
      title: '売上一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Sale Detail Apartment',
        },
      ],
      activeKey: 'admin.sale.detail.apartment'
    },
    component: () => import("@pages/admin/sale/SaleDetailApartmentPage"),
  },
  {
    path: 'sale/detail/:customer_id/customer/:apartment_id/apartment',
    name: ROUTES.ADMIN.SALE_LIST_DETAIL_CUSTOMER,
    meta: {
      title: '売上一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Sale Detail Customer',
        },
      ],
      activeKey: 'admin.sale.detail.customer'
    },
    component: () => import("@pages/admin/sale/SaleDetailCustomerPage"),
  },
  {
    path: 'sale/edit',
    name: ROUTES.ADMIN.SALE_EDIT,
    meta: {
      title: '売上一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Sale List',
        },
      ],
      activeKey: 'admin.sale.list'
    },
    component: () => import("@pages/admin/sale/SaleDetail"),
  },
];
