import { ADMIN_URL_API } from './api';

export const APP_NAME = 'Mansion';
export const APP_VERSION_URL = null;

export const ROLES = {
    ADMIN: 1,
};

export const ROUTES = {
    ROOT: 'ROOT',
    DEV_GUIDE: 'DEV_GUIDE',
    ADMIN: {
        AUTH_LOGIN: 'ADMIN.LOGIN',
        AUTH_LOGOUT: 'ADMIN.LOGOUT',
        AUTH_FORGOT_PASSWORD: 'ADMIN.FORGOT_PASSWORD',
        AUTH_RESET_PASSWORD: 'ADMIN.RESET_PASSWORD',
        PROFILE: 'ADMIN.PROFILE',
        DASHBOARD: 'ADMIN.DASHBOARD',
        ALL_USERS_LIST: 'ADMIN.ALL_USERS_LIST',
        USERS_CREATE: 'ADMIN.USER_CREATE',
        APARTMENT_LIST: 'ADMIN.APARTMENT_LIST',
        APARTMENT_CREATE: 'ADMIN.APARTMENT_CREATE',
        APARTMENT_EDIT: 'ADMIN.APARTMENT_EDIT',
        BOOK_MASTER_LIST: 'ADMIN.BOOK_MASTER_LIST',
        BOOK_MASTER_CREATE: 'ADMIN.BOOK_MASTER_CREATE',
        BOOK_MASTER_EDIT: 'ADMIN.BOOK_MASTER_EDIT',
        BOOKING_CUSTOMER_LIST: 'ADMIN.BOOKING_CUSTOMER_LIST',
        CUSTOMER_LIST: 'ADMIN.CUSTOMER_LIST',
        CUSTOMER_CREATE: 'ADMIN.CUSTOMER_CREATE',
        CUSTOMER_EDIT: 'ADMIN.CUSTOMER_EDIT',


        CUSTOMER_VITAL_LIST: 'ADMIN.CUSTOMER_VITAL_LIST',
        CUSTOMER_VITAL_CREATE: 'ADMIN.CUSTOMER_VITAL_CREATE',
        CUSTOMER_VITAL_EDIT: 'ADMIN.CUSTOMER_VITAL_EDIT',
        CUSTOMER_MEAL_LIST: 'ADMIN.CUSTOMER_MEAL_LIST',
        CUSTOMER_MEAL_CREATE: 'ADMIN.CUSTOMER_MEAL_CREATE',
        CUSTOMER_MEAL_EDIT: 'ADMIN.CUSTOMER_MEAL_EDIT',

        CATEGORY_LIST: 'ADMIN.CATEGORY_LIST',
        MENU_LIST: 'ADMIN.MENU_LIST',
        ACCOUNT_EDIT: 'ACCOUNT_EDIT',
        ACCOUNT_CREATE: 'ACCOUNT_CREATE',
        ACCOUNT_VERIFY_EMAIL: 'ACCOUNT_VERIFY_EMAIL',
        ACCOUNT_VERIFY_RESEND_EMAIL: 'ACCOUNT_VERIFY_RESEND_EMAIL',
        ACCOUNT_EDIT_PASSWORD: 'ACCOUNT_EDIT_PASSWORD',
        ORDER_LIST: 'ADMIN.ORDER_LIST',
        SALE_LIST: 'ADMIN.SALE_LIST',
        SALE_LIST_DETAIL: 'ADMIN.SALE_LIST_DETAIL',
        SALE_LIST_DETAIL_APARTMENT: 'ADMIN.SALE_LIST_DETAIL_APARTMENT',
        SALE_LIST_DETAIL_CUSTOMER: 'ADMIN.SALE_LIST_DETAIL_CUSTOMER',
        SALE_DETAIL: 'ADMIN.SALE_DETAIL',
        BIG_CATEGORY_LIST: 'ADMIN.BIG_CATEGORY_LIST',
        BIG_CATEGORY_CREATE: 'ADMIN.BIG_CATEGORY_CREATE',
        BIG_CATEGORY_EDIT: 'ADMIN.BIG_CATEGORY_EDIT',
        UPLOAD_CSV: 'ADMIN.UPLOAD_CSV',
        DOWNLOAD_CSV: 'ADMIN.DOWNLOAD_CSV',
        RECEIPTS_INVOICE: 'ADMIN.RECEIPTS_INVOICE',
        RECEIPTS_INVOICE_CREATE      : 'ADMIN.RECEIPTS_INVOICE_CREATE',
        RECEIPTS_INVOICE_EDIT      : 'ADMIN.RECEIPTS_INVOICE_EDIT',
        RECEIPTS_INVOICE_LIST      : 'ADMIN.RECEIPTS_INVOICE_LIST',
        FACILITY_CREATE : 'ADMIN.FACILITY_CREATE',
        FACILITY_EDIT : 'ADMIN.FACILITY_EDIT',
        FACILITY_LIST: 'ADMIN.FACILITY_LIST',
        FACILITY_MANAGE_STATUS: 'ADMIN.FACILITY_MANAGE_STATUS',
        INFORMATION_CREATE: 'ADMIN.INFORMATION_CREATE',
        INFORMATION_EDIT: 'ADMIN.INFORMATION_EDIT',
        INFORMATION_LIST: 'ADMIN.INFORMATION_LIST',
        FACILITY_PLAN_CREATE    : 'ADMIN.FACILITY_PLAN_CREATE',
        FACILITY_PLAN_LIST      : 'ADMIN.FACILITY_PLAN_LIST',
        FACILITY_PLAN_EDIT      : 'ADMIN.FACILITY_PLAN_EDIT',
        RESERVATION_CREATE      : 'ADMIN.RESERVATION_CREATE',
        RESERVATION_EDIT      : 'ADMIN.RESERVATION_EDIT',
        RESERVATION_LIST      : 'ADMIN.RESERVATION_LIST',
        BOOKING_SCHEDULE_LIST: 'ADMIN.BOOKING_SCHEDULE_LIST',
        BEACONS_LIST: 'ADMIN.BEACONS_LIST',
        BEACONS_CREATE : 'ADMIN.BEACONS_CREATE',
        BEACONS_EDIT : 'ADMIN.BEACONS_EDIT',
        BEACONS_HISTORY : 'ADMIN.BEACON_HISTORY',
        GATEWAY_LIST: 'ADMIN.GATEWAY_LIST',
        GATEWAY_CREATE : 'ADMIN.GATEWAY_CREATE',
        GATEWAY_EDIT : 'ADMIN.GATEWAY_EDIT',
        GATEWAY_HISTORY_LIST : 'ADMIN.GATEWAY_HISTORY_LIST',
        MEDIUM_CATEGORY_LIST: 'ADMIN.MEDIUM_CATEGORY_LIST',
        MEDIUM_CATEGORY_CREATE: 'ADMIN.MEDIUM_CATEGORY_CREATE',
        MEDIUM_CATEGORY_EDIT: 'ADMIN.MEDIUM_CATEGORY_EDIT',
        SERVICE_CATEGORY_CREATE : 'ADMIN.SERVICE_CATEGORY_CREATE',
        SERVICE_CATEGORY_EDIT : 'ADMIN.SERVICE_CATEGORY_EDIT',
        SERVICE_CATEGORY_LIST : 'ADMIN.SERVICE_CATEGORY_LIST',
        MENU_CREATE            : 'ADMIN.MENU_CREATE',
        MENU_EDIT            : 'ADMIN.MENU_EDIT',
        SMALL_CATEGORY_LIST: 'ADMIN.SMALL_CATEGORY_LIST',
        SMALL_CATEGORY_CREATE: 'ADMIN.SMALL_CATEGORY_CREATE',
        SMALL_CATEGORY_EDIT: 'ADMIN.SMALL_CATEGORY_EDIT',
        RESTAURANTS_LIST: 'ADMIN.RESTAURANTS_LIST',
        RESTAURANTS_CREATE : 'ADMIN.RESTAURANTS_CREATE',
        RESTAURANTS_QR_CODE : 'ADMIN.RESTAURANTS_QR_CODE',
        RESTAURANTS_EDIT : 'ADMIN.RESTAURANTS_EDIT',
        ANSWER_LIST: 'ADMIN.ANSWER_LIST',
        ANSWER_CREATE: 'ADMIN.ANSWER_CREATE',
        ANSWER_EDIT: 'ADMIN.ANSWER_EDIT',
        RECEIPTS_LIST: 'ADMIN.RECEIPTS_LIST',
        RECEIPTS_CREATE: 'ADMIN.RECEIPTS_CREATE',
        RECEIPTS_EDIT: 'ADMIN.RECEIPTS_EDIT',
        SETING_NOTIFICATION_MAIL_LIST: 'ADMIN.SETING_NOTIFICATION_MAIL_LIST',
        SETING_NOTIFICATION_MAIL_CREATE: 'ADMIN.SETING_NOTIFICATION_MAIL_CREATE',
        ANNOUNCEMENTS_LIST: 'ADMIN.ANNOUNCEMENTS_LIST',
        ANNOUNCEMENTS_MNG_LIST: 'ADMIN.ANNOUNCEMENTS_MNG_LIST',
        ANNOUNCEMENTS_MNG_CREATE: 'ADMIN.ANNOUNCEMENTS_MNG_CREATE',
        ANNOUNCEMENTS_MNG_EDIT: 'ADMIN.ANNOUNCEMENTS_MNG_EDIT',
        TRANSPORTS_LIST: 'ADMIN.TRANSPORTS_LIST',
        TRANSPORTS_CREATE: 'ADMIN.TRANSPORTS_CREATE',
        TRANSPORTS_EDIT: 'ADMIN.TRANSPORTS_EDIT',
        SALE_EDIT: 'ADMIN.SALE_EDIT',
        INVOICE_RECEIPT: 'ADMIN.INVOICE_RECEIPT',
        INVOICE_RECEIPT_DELETE: 'ADMIN.INVOICE_RECEIPT_DELETE',
        INVOICE_RECEIPT_LIST: 'ADMIN.INVOICE_RECEIPT_LIST',
        ROOMSERVICE_ORDER_CREATE: 'ADMIN.ROOMSERVICE_ORDER_CREATE',
        ROOMSERVICE_ORDER_EDIT: 'ADMIN.ROOMSERVICE_ORDER_EDIT',


        // kyykhang
        MENU_STUDENT_LIST: 'ADMIN.MENU_STUDENT_LIST',
        MENU_STUDENT_CREATE: 'ADMIN.MENU_STUDENT_CREATE',
        MENU_STUDENT_EDIT: 'ADMIN.MENU_STUDENT_EDIT',
        MENU_STUDENT_DELETE: 'ADMIN.MENU_STUDENT_DELETE',

        KYY_RESTAURANT_LIST: 'ADMIN.KYY_RESTAURANT_LIST',
        KYY_RESTAURANT_CREATE: 'ADMIN.KYY_RESTAURANT_CREATE',
        KYY_RESTAURANT_EDIT: 'ADMIN.KYY_RESTAURANT_EDIT',
        KYY_RESTAURANT_DELETE: 'ADMIN.KYY_RESTAURANT_DELETE',

        KYY_STUDENT_ORDER_LIST: 'ADMIN.KYY_STUDENT_ORDER_LIST',
        KYY_STUDENT_ORDER_CREATE: 'ADMIN.KYY_RESTAURANT_CREATE',
        KYY_STUDENT_ORDER_EDIT: 'ADMIN.KYY_RESTAURANT_EDIT',

        KYY_ORDER_EXPORT: 'ADMIN.KYY_ORDER_EXPORT',

        KYY_CATEGORY_LIST: 'ADMIN.KYY_CATEGORY_LIST',
        KYY_CATEGORY_CREATE: 'ADMIN.KYY_CATEGORY_CREATE',
        KYY_CATEGORY_EDIT: 'ADMIN.KYY_CATEGORY_EDIT',
        KYY_CATEGORY_DELETE: 'ADMIN.KYY_CATEGORY_DELETE',

        KYY_CATEGORY_MENU_LIST: 'ADMIN.KYY_CATEGORY_MENU_LIST',
        KYY_CATEGORY_MENU_CREATE: 'ADMIN.KYY_CATEGORY_MENU_CREATE',
        KYY_CATEGORY_MENU_EDIT: 'ADMIN.KYY_CATEGORY_MENU_EDIT',
        KYY_CATEGORY_MENU_DELETE: 'ADMIN.KYY_CATEGORY_MENU_DELETE',

        KYY_STUDENT_INVOICE_LIST: 'ADMIN.KYY_STUDENT_INVOICE_LIST',
        KYY_STUDENT_INVOICE_CREATE: 'ADMIN.KYY_STUDENT_INVOICE_CREATE',
        
        KYY_TABLE_ORDER_LIST: 'ADMIN.KYY_TABLE_ORDER_LIST',
        KYY_TABLE_ORDER_CREATE: 'ADMIN.KYY_TABLE_ORDER_CREATE',
        KYY_TABLE_ORDER_EDIT: 'ADMIN.KYY_TABLE_ORDER_EDIT',
    },
};

export const AUTH = {
    admin: {
        api_url: ADMIN_URL_API,
        role_id: 1,
        routes: {
            login: ROUTES.ADMIN.AUTH_LOGIN,
            profile: ROUTES.ADMIN.PROFILE,
            dashboard: ROUTES.ADMIN.DASHBOARD,
            reset_password: ROUTES.ADMIN.AUTH_RESET_PASSWORD,
            forgot_password: ROUTES.ADMIN.AUTH_FORGOT_PASSWORD,
        }
    },
    user: {
        role: 'user',
    }
};

export const UNSIGNED_SMALL_INT_MAX_VALUE = 65535
export const SMALL_INT_MAX_VALUE = 32767
export const UNSIGNED_TINY_INT_MAX_VALUE = 255
export const UNSIGNED_INT_MAX_VALUE = 2147483647
export const PLAN_PRICE_MAX_VALUE = 16000000

export const VALIDATE = {
    V_VARCHAR_MAXLENGTH: 191,
    V_EMAIL_REQUIRED: `required|email|max:191`,
    V_SMALL_INT_UNSIGNED_REQUIRED : 'required|max_value:' + UNSIGNED_SMALL_INT_MAX_VALUE + '|min_value:1',
    V_SMALL_INT_UNSIGNED : 'max_value:' + UNSIGNED_SMALL_INT_MAX_VALUE + '|min_value:1',
    V_SMALL_INT_REQUIRED : 'required|max_value:' + SMALL_INT_MAX_VALUE + '|min_value:1',
    V_SMALL_INT : 'max_value:' + SMALL_INT_MAX_VALUE + '|min_value:1',
    V_TINY_INT_REQUIRED : 'required|max_value:' + UNSIGNED_TINY_INT_MAX_VALUE + '|min_value:1',
    V_UNSIGNED_INT       : 'max_value:' + UNSIGNED_INT_MAX_VALUE + '|min_value:1',
    V_PLAN_PRICE     : 'max_value:' + PLAN_PRICE_MAX_VALUE + '|min_value:1'
};

export const BASIC_TABLE = {
    TYPE_SEARCH: "TYPE_SEARCH",
    TYPE_INPUT: "TYPE_INPUT",
    TYPE_SELECT: "TYPE_SELECT",
    TYPE_MULTI_SELECT: "TYPE_MULTI_SELECT",
    TYPE_DATE_PICKER: "TYPE_DATE_PICKER",
    TYPE_DATE_PICKER_FROM: "TYPE_DATE_PICKER_FROM",

};
