import _ from "lodash";
import { APP_NAME } from "@constants";
import i18n from "@i18n";
import router from "@routes"
import { AUTH } from "@constants";
import { store } from '@store'

export const __setLocalStorage = (key, value) => {
    return localStorage.setItem(APP_NAME + key, value);
}

export const __getLocalStorage = key => {
    return localStorage.getItem(APP_NAME + key);
}

export const __removeLocalStorage = key => {
    return localStorage.removeItem(APP_NAME + key)
}

export const __noticeSuccess = (message, options = {}) => {
    toastr.success(message, '')
    // $('.app-page-container').pgNotification({
    //     style: 'flip',
    //     message: message,
    //     position: 'top-right',
    //     timeout: 3000,
    //     type: 'success',
    //     ...options
    // }).show();
}

export const __noticeError = (message, options = {}) => {
    if (!message || message.length < 0) {
        message = i18n.tc('common.error')
    }
    // toastr.options.timeOut = 0;
    // toastr.options.extendedTimeOut = 0;
    toastr.error(message, '');
    // $('.app-page-container').pgNotification({
    //     style: 'flip',
    //     message: message,
    //     position: 'top-right',
    //     timeout: 3000,
    //     type: 'danger',
    //     ...options
    // }).show();
}

export const __noticeWarning = (message, options = {}) => {
  if (!message || message.length < 0) {
    message = i18n.tc('common.error')
  }
  toastr.warning(message, '');
  // $('.app-page-container').pgNotification({
  //     style: 'flip',
  //     message: message,
  //     position: 'top-right',
  //     timeout: 3000,
  //     type: 'danger',
  //     ...options
  // }).show();
}

export const __formatDate = (dateString, format) => {
    if (!dateString || String(dateString).length == 0) {
        return '';
    }
    if (!format) {
        format = "YYYY-MM-DD"
    }
    let date = moment(dateString);
    return date.format(format);
}

export const __setOptionToolFilter = (tools, name, type, options) => {
    let filters = tools?.search?.filters;

    if (!filters) {
        return;
    }

    let newFilter = filters.map(filter => {
        if (filter.name === name && filter.type === type) {
            return { ...filter, options: options }
        } else {
            return filter
        }
    })
    tools.search.filters = _.cloneDeep(newFilter);
}

export const __getOptionFilter = (filters, name, type, options) => {
    if (!filters) {
        return;
    }

    let newFilter = filters.map(filter => {
        if (filter.name === name && filter.type === type) {
            return { ...filter, options: options }
        } else {
            return filter
        }
    })
    return _.cloneDeep(newFilter);
}

export const __formatNumber = value => {
    if (!value) {
        return value;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const __formatPriceJa = value => {
    return __formatNumber(value) + '円';
}

export const __getDayFromDate = date => {
    if (!date) {
        return null;
    }

    const mapDay = ['日', '月', '火', '水', '木', '金', '土'];
    const day = (new Date(date)).getDay()
    return {
        'text': mapDay[day],
        'code': day,
    };
}

export const __downloadFromUrl = url => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
}

export const __redirect = (name, params = {}, query = {}) => {
    router.push({ name, params, query }).catch(() => { });
}

export const __redirectBack = () => {
    router.go(-1);
}

export const getWithHeight = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      const { naturalWidth: width, naturalHeight: height } = img
      resolve({ width, height })
    }

    img.onerror = () => {
      reject('There was some problem with the image.')
    }
    img.src = file
  });
}

export const logoutAction = () => {
  store.commit("auth/removeAuth")
  let role = store.state.auth.currentAuth.role;
  __redirect(AUTH[role].routes.login);
}

export const illegalInteger = (num) => {
  return Number.isInteger(Number(num));
}

export const negativeSmallInt = (num) => {
  return Number(num) >= Number(-32768);
}

export const unsignedSmallInt = (num) => {
  return Number(num) <= Number(32767);
}

export const updateQueryStringParameter = (uri, key, value)  => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}

export const getLabelFromOptionArray = (optionID, optionArray) => {
  let option = optionArray.find(({id}) => id === optionID);
  if (option == undefined) {
    return '';
  }
  return option.name;
}

export const convertCurrency = (value) => {
  return new Intl.NumberFormat('en-US').format(Number(value));
}

export const getFullDateString = (dateString, format = 'YYYY-MM-DD') => {
  const date = moment(dateString, format)
  const weekDay = date.isoWeekday()
  const day = date.date()
  const month = date.month() + 1
  const year = date.year()
  const params = {
    day_of_week: i18n.t(`common_vn.days_of_week.${weekDay}`),
    day: day,
    month: month,
    year: year
  };
  return i18n.t("common_vn.date_string", params)
}
