import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'notifies',
    name: ROUTES.ADMIN.SETING_NOTIFICATION_MAIL_LIST,
    meta: {
      title: 'お知らせメール設定',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Notifies List',
        },
      ],
      activeKey: 'admin.notifies.list'
    },
    component: () => import("@pages/admin/notifies/NotifiesList"),
  },
  {
    path: 'notifies',
    name: ROUTES.ADMIN.SETING_NOTIFICATION_MAIL_CREATE,
    meta: {
      title: 'お知らせメール設定',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Notifies List',
        },
      ],
      activeKey: 'admin.notifies.list'
    },
    component: () => import("@pages/admin/notifies/NotifiesList"),
  },
  {
    path: 'notifies/:id/edit',
    name: ROUTES.ADMIN.SETING_NOTIFICATION_MAIL_EDIT,
    meta: {
      title: 'お知らせメール設定',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Notifies List',
        },
      ],
      activeKey: 'admin.notifies.list'
    },
    component: () => import("@pages/admin/notifies/NotifiesList"),
  },
];
