import { BASE_URL_API, METHODS } from '@constants/api';
import { __redirect } from '@utils';
import {store} from "@store";
import {AUTH} from "@constants";

export default {
  install(Vue, options) {
    const app = new Vue
    Vue.prototype.$request = {
      call: async function (options = {}) {
        if (!options?.noLoadingEffect && options?.noLoadingEffect != undefined) {
          var loader = app.$loading.appShow()
        }
        try {
          const response = await app.$axios(_.merge({
            baseURL: BASE_URL_API,
            timeout: 600000, //5Minute
            method: METHODS.GET,
            headers: {
              LOCALE: app.$cookies.get("lang") ? app.$cookies.get("lang") : 'ja',
            },
          }, options))
          if (!options?.noLoadingEffect && options?.noLoadingEffect != undefined) {
            loader?.hide()
          }
          if (response.data?.status === 'PERMISSION_DENIED') {
            if(options.method === METHODS.GET) {
              toastr.error(response.data.error_msg, '');
            }
            // store.commit("auth/removeAuth")
            // let role = store.state.auth.currentAuth.role;
            // __redirect(AUTH[role].routes.login);
          }
          return {
            data: response.data,
            status: response.data?.status,
            headers: response.headers,
            hasErrors: () => response.data?.status === 'PERMISSION_DENIED'
          }
        } catch (error) {
          if (!options?.noLoadingEffect && options?.noLoadingEffect != undefined) {
            loader?.hide()
          }
          if (options?.handleNotFound) {
            return __redirect('not-found')
          }
          return {
            data: error.response?.data,
            statusCode: error.response?.status,
            headers: error.response?.headers,
            hasErrors: () => true
          }
        }
      },

      get: async function (url, params = {}, options = {}) {
        return this.call(_.merge({ method: METHODS.GET, url, params }, options))
      },

      post: async function (url, data = {}, options = {}) {
        return this.call(_.merge({ method: METHODS.POST, url, data }, options))
      },

      patch: async function (url, data = {}, options = {}) {
        return this.call(_.merge({ method: METHODS.PATCH, url, data }, options))
      },

      delete: async function (url, data = {}, options = {}) {
        return this.call(_.merge({ method: METHODS.DELETE, url, data }, options))
      },

      getUrl: path => {
        return BASE_URL_API + path;
      }
    }
  }
}
