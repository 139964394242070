

export const STATUS = [
  {
    id: 1,
    name: "有効"
  },
  {
    id: 0,
    name: "無効"
  },

]

export const FILTER_STATUS = [
  {
    id: 'all',
    name: "全て"
  },

  {
    id: 1,
    name: "有効"
  },
  {
    id: 0,
    name: "無効"
  },
]

export const BEACONS_CONSTANT = {
  "STATUS" : STATUS,
  "FILTER_STATUS" : FILTER_STATUS
}
