import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'menu/create',
    name: ROUTES.ADMIN.MENU_CREATE,
    meta: {
      title: 'メニュー管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Menu List',
          'href': '/admin/menu?filters.apartment_id.equal=all&filters.category_id.equal=all&filters.status_enabled.equal=all',
        },
        {
          'label': 'breadcrumbs.Menu Create',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/menu/MenuCreateOrEdit"),
  },
  {
    path: 'menu/:id/edit',
    name: ROUTES.ADMIN.MENU_EDIT,
    meta: {
      title: 'メニュー管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Menu List',
          'href': '/admin/menu?filters.apartment_id.equal=all&filters.category_id.equal=all&filters.status_enabled.equal=all',
        },
        {
          'label': 'breadcrumbs.Menu Edit',
        },
      ],
      activeKey: 'admin.customer-vitals.list'
    },
    component: () => import("@pages/admin/menu/MenuCreateOrEdit"),
  },
];
