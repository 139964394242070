import Vue from 'vue'
import Vuex from 'vuex'
import setting from './setting'
import auth from './auth'

Vue.use(Vuex)

let store = null;

export default function () {
  store = new Vuex.Store({
    modules: {
      auth,
      setting,
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  });

  return store;
}

export { store };

