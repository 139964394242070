<template>
  <validation-provider
    :name="appKey ? `${name}-${appKey}` : name"
    :rules="rules"
    v-slot="{ errors, invalid, validated }"
  >
    <div class="app-gr-control">
      <div
        class="app-gr-control"
        :class="[invalid && validated ? 'app-error' : '']"
      >
        <select
          ref="select"
          class="form-control"
          :value="value"
          :disabled="disabled"
          style="width: 100%;">
          <slot name="after"></slot>

          <template v-if="optionsData">
            <option
              :key="index"
              v-for="(item, index) in optionsData"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </template>

          <slot name="before"></slot>
        </select>
        <label v-if="invalid && validated" class="error">
          {{ errors[0] }}
        </label>
      </div>
    </div>
  </validation-provider>
</template>

<script>
export default {
  name: "AppSelect",

  props: [
    "options",
    "value",
    "name",
    "rules",
    "optionsData",
    "onChange",
    "disabled",
    "appKey"
  ],

  mounted: function () {
    this.initSelect();
  },

  watch: {
    '$route.params.query': {
      handler: function(search) {
        $(".select2-dropdown").hide();
        $(this.$refs.select).select2();

      },
      deep: true,
      immediate: true
    },

    /*on change value*/
    value: function (value, oldValue) {
      let vm = this;
      $(this.$refs.select).val(this.value).trigger("change", { ignore: true });
      vm.$emit("change", this.value);
    },

    /*on change options*/
    options: function (options, oldOptions) {
      if (options && JSON.stringify(options) !== JSON.stringify(oldOptions)) {
        $(this.$refs.select).select2(options);
      }
    },

    optionsData: function (value, oldValue) {
      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        this.$nextTick(() => {
          $(this.$refs.select).off().select2("destroy");
          this.initSelect();
          $(this.$refs.select).val(this.value).trigger("change");
        });
      }
    },
  },

  methods: {
    initSelect() {
      let vm = this;
      var onChange = this.onChange;
      $(this.$refs.select).val(this.value);
      $(this.$refs.select)
        .select2({
          ...this.options,
          language: {
            noResults: () => {
              return this.$t("common.no_select");
            },
          },
        })
        .on("change", function (ev, args) {
          if (!(args && "ignore" in args)) {
            if (onChange) {
              onChange($(this).val());
            }
            vm.$emit("input", $(this).val());
          }
        });
      if (this.options?.containerCssClass) {
        $("." + this.options?.containerCssClass)
          .closest(".select2-container")
          .addClass(this.options?.containerCssClass);
      }
    },
  },

  destroyed: function () {
    $(this.$refs.select).off().select2("destroy");
  },
};
</script>
