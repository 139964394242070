import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import _ from 'lodash'
import App from '../App.vue';
import { store } from '@store'
import { APP_NAME } from '@constants';
import appMiddleware from "@routes/middlewares/appMiddleware";

Vue.use(Router);

const router = new Router({ mode: 'history', routes });

router.beforeEach((to, from, next) => {
    let language = App.$cookies.get(APP_NAME + 'lang');
    // if (!language) {
        App.$cookies.set("lang", 'ja');
        language = 'ja';
    // }

    App.$localize(language);
    App.i18n.locale = language;
    store.commit('setting/setPrevRouter', store.state.setting.currentRoute);
    store.commit('setting/setCurrentRoute', to);
    //breadcrumbs start
    let breadcrumbs = to.matched.map(match => match.meta.breadcrumb)
    let routeNames = to.matched.map(match => match.name)
    var breadCrumbsGroups = [];
    _.forEach(breadcrumbs, (breadcrumb, index) => {
        if (breadcrumb) {
            if (_.isArray(breadcrumb)) {
                _.forEach(breadcrumb, (breadcrumbSub) => {
                    breadCrumbsGroups = [...breadCrumbsGroups, {
                        name: breadcrumbSub,
                        routeName: routeNames[index]
                    }]
                })
            } else {
                breadCrumbsGroups = [...breadCrumbsGroups, {
                    name: breadcrumb,
                    routeName: routeNames[index]
                }]
            }
        }
    })
    store.commit('setting/setBreadCrumbs', breadCrumbsGroups);
    // middleware register
    let routerMiddlewares = to.matched.map(match => match.meta.middlewares)
    routerMiddlewares = _.spread(_.union)(_.compact(routerMiddlewares))
    let middlewares = [appMiddleware, ...routerMiddlewares]
    const context = { to, from, next }
    return middlewares[0]({
        ...context,
        next: middlewarePipeline(context, middlewares, 1)
    })
});
const DEFAULT_TITLE = 'Kỷ Y - Khang';
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

/* run middleware chain */
function middlewarePipeline(context, middlewares, index) {
    const nextMiddleware = middlewares[index]

    if (!nextMiddleware) {
        return context.next
    }

    return async () => {
        const nextPipeline = middlewarePipeline(context, middlewares, index + 1)
        await nextMiddleware({ ...context, next: nextPipeline })
    }
}

export default router;

