import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'restaurants',
    name: ROUTES.ADMIN.RESTAURANTS_LIST,
    meta: {
      title: 'レストラン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Restaurants List',
        },
      ],
      activeKey: 'admin.master-booking.list'
    },
    component: () => import("@pages/admin/restaurants/RestaurantListPage"),
  },
  {
    path: 'restaurants/:id/edit',
    name: ROUTES.ADMIN.RESTAURANTS_EDIT,
    meta: {
      title: 'レストラン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Restaurants List',
          'href': '/admin/restaurants?filters.status_enabled.equal=all&filters.apartment_id.equal=all&filters.facility_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Restaurants Edit',
        },
      ],
      activeKey: 'admin.master-booking.create'
    },
    component: () => import("@pages/admin/restaurants/RestaurantCreateOrEdit"),
  },
  {
    path: 'restaurants/create',
    name: ROUTES.ADMIN.RESTAURANTS_CREATE,
    meta: {
      title: 'レストラン管理',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Restaurants List',
          'href': '/admin/restaurants?filters.status_enabled.equal=all&filters.apartment_id.equal=all&filters.facility_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Restaurants Create',
        },
      ],
      activeKey: 'admin.master-booking.create'
    },
    component: () => import("@pages/admin/restaurants/RestaurantCreateOrEdit"),
  },
];
