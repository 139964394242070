

export const STATUS = [
    {
      id: 1,
      name: "有効"
    },
    {
      id: 0,
      name: "無効"
    },

  ]

  export const FILTER_STATUS = [
    {
      id: 'all',
      name: "全て"
    },

    {
      id: 1,
      name: "有効"
    },
    {
      id: 0,
      name: "無効"
    },
  ]

export const STATUS_ENABLED = 1

export const RESTAURANTS_CONSTANT = {
  "STATUS": STATUS,
  "FILTER_STATUS": FILTER_STATUS,
  "STATUS_ENABLED": STATUS_ENABLED
}

export const COLOR_STATUS_TABLE_OK = "#0fb131"
export const COLOR_STATUS_TABLE_NG = "#ED8077"
