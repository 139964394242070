import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'receipts',
    name: ROUTES.ADMIN.RECEIPTS_LIST,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Receipts List',
        },
      ],
      activeKey: 'admin.receipts.list'
    },
    
    component: () => import("@pages/admin/receipts/ReceiptsList"),
  },

  {
    path: 'receipts/create',
    name: ROUTES.ADMIN.RECEIPTS_CREATE,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Receipts List',
          'href': '/admin/receipts?filters.status_enabled.equal=all&filters.apartment_id.equal=all&filters.from_date.between=2022-03-30%2013%3A12%3A00&filters.to_date.between=2022-03-30%2013%3A12%3A00',
        },
        {
          'label': 'breadcrumbs.Receipts Create',
        },
      ],
      activeKey: 'admin.receipts.create'
    },
    component: () => import("@pages/admin/receipts/ReceiptsCreateOrEdit"),
  },

  {
    path: 'receipts/:id/edit',
    name: ROUTES.ADMIN.RECEIPTS_EDIT,
    meta: {
      breadcrumb: [
        {
          'label': 'breadcrumbs.Receipts List',
          'href': '/admin/receipts?filters.status_enabled.equal=all&filters.apartment_id.equal=all&filters.from_date.between=2022-03-30%2013%3A12%3A00&filters.to_date.between=2022-03-30%2013%3A12%3A00',
        },
        {
          'label': 'breadcrumbs.Receipts Edit',
        },
      ],
      activeKey: 'admin.receipts.edit'
    },
    component: () => import("@pages/admin/receipts/ReceiptsCreateOrEdit"),
  },
];
