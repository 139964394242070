<template>
  <app-modal
    modal-class="modal-confirm slide-up"
    size="md"
    v-on="$listeners"
    v-bind="$attrs"
    ref="modal"
    :cancelText="cancelText"
    :confirmTextText="confirmTextText"
  >
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
    <template v-slot:footer>
      <button
        @click="$emit('cancel')"
        type="button"
        :class="['btn btn-default m-l-30', cancelClass]"
        data-dismiss="modal"
      >
        {{ cancelText ? cancelText :  $t("common.no") }}
      </button>
      <button
        @click="$emit('confirmed')"
        type="button"
        :class="['btn btn-primary', confirmClass]"
      >
        {{ confirmTextText ? confirmTextText : $t("common.confirm") }}
      </button>
    </template>
  </app-modal>
</template>

<script>
export default {
  name: "AppModalConfirm",

  props: {
    confirmClass: String,
    cancelClass: String,
    cancelText: String,
    confirmTextText: String,
  },

  methods: {
    hide() {
      this.$refs.modal.hide();
    },

    show() {
      this.$refs.modal.show();
    },
  },
};
</script>
