import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'medium-category',
    name: ROUTES.ADMIN.MEDIUM_CATEGORY_LIST,
    meta: {
      title: '中カテゴリ一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Medium Category List',
        },
      ],
      activeKey: 'admin.medium-category.list'
    },

    component: () => import("@pages/admin/qa_chatbots/MediumCategoryList"),
  },

  {
    path: 'medium-category/create',
    name: ROUTES.ADMIN.MEDIUM_CATEGORY_CREATE,
    meta: {
      title: '中カテゴリ一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Medium Category List',
          'href': '/admin/medium-category?filters.big_category_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Medium Category Create',
        },
      ],
      activeKey: 'admin.medium-category.create'
    },
    component: () => import("@pages/admin/qa_chatbots/MediumCategoryCreateOrEdit"),
  },

  {
    path: 'medium-category/:id/edit',
    name: ROUTES.ADMIN.MEDIUM_CATEGORY_EDIT,
    meta: {
      title: '中カテゴリ一覧',
      breadcrumb: [
        {
          'label': 'breadcrumbs.Medium Category List',
          'href': '/admin/medium-category?filters.big_category_id.equal=all',
        },
        {
          'label': 'breadcrumbs.Medium Category Edit',
        },
      ],
      activeKey: 'admin.medium-category.edit'
    },
    component: () => import("@pages/admin/qa_chatbots/MediumCategoryCreateOrEdit"),
  },
];
