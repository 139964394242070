import {extend, localize} from "vee-validate";
import {
  between, confirmed, email, length,
  max, max_value, min, min_value, regex, required, required_if, double, numeric
} from "vee-validate/dist/rules";
import en from "./en";
import ja from "./ja";
require('./custom');

// Install rules
extend("required", required);
extend("required_noname", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("between", between);
extend("length", length);
extend("confirmed", confirmed);
extend("min_value", min_value);
extend("max_value", max_value);
extend("regex", regex);
extend("required_if", required_if);
extend("double", double);
extend("numeric", numeric);

// Install messages
localize({
  ja,
  en
});

localize('ja');
