import {AUTH} from "@constants"
import { __getLocalStorage } from '@utils'

export default function () {
  var data = {};
  _.forEach(Object.keys(AUTH), role => {
    data[role] = {
      role: role,
      user: JSON.parse(__getLocalStorage(`${role}_auth.user`)),
      token: __getLocalStorage(`${role}_auth.token`),
      expire_time: Number(__getLocalStorage(`${role}_auth.expire_time`))
    }
  })
  return {
    currentAuth: {
      role: null,
      user: null,
      token: null,
      expire_time: null,
    },
    data: data,
  }
}
